import { Box, Center, Spinner, VStack } from '@chakra-ui/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import { fetchNewsFeedPage, fetchNewsHighlights } from '../../services/api';
import { Content } from '../../types/contentTypes';
import ContentListComponent from '../../components/ContentListComponent';
import { DetachListComponent } from '../../components/DetachListComponent';
import FeedHeader from '../../components/FeedHeader';
import FeedSkeleton from '../../components/Fs/Skeletons/FeedSkeleton';
import { t } from 'i18next';

function NewsFeed() {
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery<Content[]>({
            queryKey: ['news-feed'],
            queryFn: ({ pageParam = 1 }) =>
                fetchNewsFeedPage(pageParam).then((content) => content),
            getNextPageParam: (lastPage, pages) =>
                lastPage.length === 25 ? pages.length + 1 : undefined,
        });
    const { data: detachContent, isLoading: isLoadingDetach } = useQuery({
        queryKey: ['news-detach'],
        queryFn: () => fetchNewsHighlights().then((content) => content),
    });
    const { companyConfig } = useConfigContext();

    const content = data?.pages.flatMap((page) => page);

    const feedContent = content?.filter((content) => !content.is_highlight);

    const ifLastContentWasViewd = () => {
        hasNextPage && fetchNextPage();
    };

    return (
        <>
            <Helmet>
                <title>{`${t('feed.Notícia')} - ${companyConfig?.name}`}</title>
            </Helmet>

            <FeedHeader title={t('feed.Notícia')} search />

            {(isLoading && isLoadingDetach) === true ? (
                <FeedSkeleton />
            ) : (
                <VStack align="stretch" spacing="0">
                    {detachContent && detachContent.length > 0 && (
                        <Box display="flex" flexDir="column" w="full">
                            <Box>
                                <DetachListComponent
                                    detachContent={detachContent}
                                />
                            </Box>
                        </Box>
                    )}
                    {feedContent && (
                        <Box px="4">
                            <ContentListComponent
                                feedContent={feedContent}
                                onLastContentView={ifLastContentWasViewd}
                            />
                        </Box>
                    )}
                </VStack>
            )}
            {isFetchingNextPage && (
                <Box position="fixed" bottom="90px" w="full">
                    <Center>
                        <Spinner />
                    </Center>
                </Box>
            )}
        </>
    );
}

export default NewsFeed;
