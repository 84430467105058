import { Box, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useConfigContext } from '../../context/configContext';
import { sendForgotPasswordEmail } from '../../services/api';
import { Helmet } from 'react-helmet-async';
import FsInputText from '../Fs/InputText';
import FsButton from '../Fs/Button';
import useFsToast from '../Fs/Toast';
import Translator from '../Translator';
import { t } from 'i18next';

type FormData = {
    login: string;
};

const schema = yup.object({
    login: yup.string().required('form.required'),
});

type ForgotPasswordProps = {
    notHaveAccount: () => void;
    onClose?: () => void;
};

function ForgotPasswordComponent({
    notHaveAccount,
    onClose,
}: ForgotPasswordProps) {
    const [isLoading, setIsLoading] = useState(false);
    const company = useConfigContext();
    const { companyConfig } = useConfigContext();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            login: '',
        },
    });

    const toast = useFsToast();

    function handleForgotPasword(login: string) {
        setIsLoading(true);

        sendForgotPasswordEmail(
            login,
            company.companyConfig ? company.companyConfig.client_token : ''
        )
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    toast({
                        description: response.data.message,
                        status: 'success',
                    });
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response?.status === 404) {
                    toast({
                        description: t('form.incorrect'),
                        status: 'warning',
                        duration: null,
                    });
                } else if (error.response?.status === 422) {
                    toast({
                        description: error.response?.data.message,
                        status: 'warning',
                        duration: null,
                    });
                }
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${t('forgotPassword.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <Text
                as="h1"
                fontSize="xl"
                color="gray.600"
                fontWeight="700"
                textAlign="center"
                mb="8"
            >
                <Translator path="translations.forgotPassword.title" />
            </Text>
            <Text
                as="h2"
                fontSize="lg"
                color="gray.600"
                textAlign="center"
                mb="8"
            >
                <Translator path="translations.forgotPassword.description" />
            </Text>

            <VStack align="stretch" spacing="5">
                <Box>
                    <Controller
                        control={control}
                        name="login"
                        render={({ field: { onChange, value } }) => (
                            <FsInputText
                                label={companyConfig?.label_login || 'Login'}
                                placeholder={
                                    companyConfig?.label_login || 'Login'
                                }
                                onChange={onChange}
                                value={value}
                                error={errors.login}
                            />
                        )}
                    />
                </Box>

                <Box>
                    <FsButton
                        block={true}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        onClick={handleSubmit((data) =>
                            handleForgotPasword(data.login)
                        )}
                    >
                        <Translator path="translations.forgotPassword.button" />
                    </FsButton>
                </Box>

                <Box textAlign="center">
                    <Text
                        as="button"
                        fontSize="xs"
                        color="gray.400"
                        onClick={notHaveAccount}
                    >
                        <Translator path="translations.forgotPassword.dontHaveAcccount" />
                        <strong>
                            {' '}
                            <Translator path="translations.forgotPassword.createNow" />
                        </strong>
                    </Text>
                </Box>
            </VStack>
        </>
    );
}

export default ForgotPasswordComponent;
