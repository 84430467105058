import { Tag, TagProps } from '@chakra-ui/react';

type TagComponentProps = TagProps & {
    title: string;
};

function TagComponent({ title, ...rest }: TagComponentProps) {
    return (
        <Tag
            bgColor="brand.primary"
            color="white"
            fontSize="2xs"
            noOfLines={1}
            p="2"
            {...rest}
        >
            {title}
        </Tag>
    );
}

export default TagComponent;
