/* eslint-disable no-restricted-globals */
import { AspectRatio, Box, Text, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import FeedHeader from '../../components/FeedHeader';
import Translator from '../../components/Translator';
import { useConfigContext } from '../../context/configContext';

function TVOnline() {
    const { companyConfig } = useConfigContext();

    return (
        <>
            <Helmet>
                <title>{`${t('feed.TVOnline')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>

            <VStack spacing="0" align="stretch">
                <FeedHeader title={t('feed.TVOnline')} />

                <Box px="4">
                    <Box bgColor="blackAlpha.300" p="2">
                        <AspectRatio ratio={16 / 9}>
                            <iframe
                                allowFullScreen
                                title="tvonline-player"
                                src={
                                    companyConfig?.tv_online_url
                                        ? companyConfig?.tv_online_url
                                        : ''
                                }
                                style={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            />
                        </AspectRatio>
                    </Box>
                </Box>

                <Box p="4">
                    <Text color="gray.600">
                        <Translator path="translations.tvonline.description" />
                    </Text>
                </Box>
            </VStack>
        </>
    );
}

export default TVOnline;
