import { Box, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useConfigContext } from '../../context/configContext';
import { fetchTermsOfUse } from '../../services/api';
import { Terms } from '../../types/contentTypes';
import { Helmet } from 'react-helmet-async';
import FsSkeletonText from '../Fs/Skeletons/SkeletonText';
import Translator from '../Translator';
import { t } from 'i18next';

function TermsOfUseComponent() {
    const { companyConfig } = useConfigContext();

    const { isLoading, data } = useQuery<Terms>({
        queryKey: ['terms'],
        queryFn: () =>
            fetchTermsOfUse(companyConfig ? companyConfig.client_token : ''),
    });

    return (
        <>
            <Helmet>
                <title>{`${t('termsOfUse')} - ${companyConfig?.name}`}</title>
            </Helmet>
            <Text
                as="h1"
                fontSize="3xl"
                color="gray.600"
                fontWeight="700"
                textAlign="center"
                mb="8"
            >
                <Translator path="translations.termsOfUse" />
            </Text>
            {isLoading ? (
                <FsSkeletonText noOfLines={15} />
            ) : (
                <Box
                    dangerouslySetInnerHTML={{ __html: data ? data.text : '' }}
                />
            )}
        </>
    );
}

export default TermsOfUseComponent;
