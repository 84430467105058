import { Box, VStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FsInputSearch from '../Fs/InputSearch';

type FeedHeaderProps = {
    title: string;
    search?: boolean;
};

export default function FeedHeader({ title, search = false }: FeedHeaderProps) {
    const [searchParams] = useSearchParams();
    const queryParam = searchParams.get('query');
    const query = queryParam ? queryParam : '';
    const [searchInputValue, setSearchInputValue] = useState(query);
    const navigate = useNavigate();

    function handleSearch() {
        searchInputValue !== '' &&
            navigate(`/app/search?query=${searchInputValue}`);
    }

    return (
        <VStack align="stretch" spacing="0">
            {search && (
                <Box p="4">
                    <FsInputSearch
                        value={searchInputValue}
                        onSearch={handleSearch}
                        setSearchInputValue={setSearchInputValue}
                    />
                </Box>
            )}
            <Box px="4" mb="2">
                <Text color="brand.primary" fontSize="2xl" fontWeight="bold">
                    {title}
                </Text>
            </Box>
        </VStack>
    );
}
