import {
    Box,
    Button,
    Center,
    Icon,
    Image,
    Text,
    useTheme,
} from '@chakra-ui/react';
import { lighten } from 'polished';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import Translator from '../Translator';

type FixedConfigPageProps = {
    children: React.ReactNode;
};

function FixedConfigPage({ children }: FixedConfigPageProps) {
    const { companyConfig } = useConfigContext();
    const { colors } = useTheme();
    const navigate = useNavigate();

    return (
        <Box w="100vw" h="100vh" display="flex" flexDirection="column">
            <Box p="0" pl="4" as="header" flex="0 1 0%">
                <Button
                    bgColor="transparent"
                    _hover={{
                        bgColor: 'transparent',
                    }}
                    onClick={() => navigate(-1)}
                >
                    <Icon boxSize="1rem" as={MdClose} />{' '}
                    <Text fontSize="2xs">
                        <Translator path="translations.close" />
                    </Text>
                </Button>
            </Box>
            <Box
                flex="1 1 0%;"
                p="0"
                px="4"
                m="4"
                mt="0"
                overflowY="auto"
                overflowX="hidden"
                sx={{
                    '::-webkit-scrollbar': {
                        width: '2',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: lighten(0.2, colors.brand.primary),
                        height: '50%',
                        borderRadius: '20',
                    },
                    '::-webkit-scrollbar-track': {
                        background: colors.gray[50],
                        borderRadius: '20',
                        marginTop: '20',
                        marginBottom: '20',
                    },
                }}
            >
                {companyConfig?.assets.logo && (
                    <Center my="8">
                        <Image
                            src={companyConfig?.assets.logo}
                            height="100%"
                            maxHeight="60px"
                        />
                    </Center>
                )}
                {children}
            </Box>
        </Box>
    );
}

export default FixedConfigPage;
