import { Text, useTheme, HStack, Box } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import useHighlightPerUrl from '../../hooks/highlightPerUrl';

type MenuDrawerItemProps = {
    url: string;
    title: string;
    Icon: IconType;
    onClick?: () => void;
};

function MenuDrawerItem({ title, url, Icon, onClick }: MenuDrawerItemProps) {
    const { colors } = useTheme();
    const isHighlightPerUrl = useHighlightPerUrl(url);

    return (
        <Box mb="6">
            <Link onClick={() => onClick && onClick()} to={url}>
                <HStack>
                    <Icon
                        size="20"
                        color={
                            isHighlightPerUrl
                                ? colors.brand.primary
                                : colors.gray[600]
                        }
                    />
                    <Text
                        fontSize="md"
                        fontWeight="400"
                        color={
                            isHighlightPerUrl
                                ? colors.brand.primary
                                : colors.gray[600]
                        }
                    >
                        {title}
                    </Text>
                </HStack>
            </Link>
        </Box>
    );
}

export default MenuDrawerItem;
