import { Box, Center, VStack, Image, Text, useTheme } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import { useConfigContext } from '../../context/configContext';
import { lighten } from 'polished';
import { Terms } from '../../types/contentTypes';
import { fetchOptIn, fetchTermsOfUse } from '../../services/api';
import FsSkeletonText from '../../components/Fs/Skeletons/SkeletonText';
import { useUserAuthContext } from '../../context/userAuthContext';
import FsInputPassword from '../../components/Fs/InputPassword';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FsButton from '../../components/Fs/Button';
import FsCheckbox from '../../components/Fs/Checkbox';
import useFsToast from '../../components/Fs/Toast';
import Translator from '../../components/Translator';
import { t } from 'i18next';

type FormData = {
    password: string;
    passwordConfirmation: string;
    accepted: boolean;
};

const schema = yup.object({
    password: yup
        .string()
        .required('form.required')
        .min(6, 'form.sixCaracteres')
        .matches(/[a-z]+/, 'form.passRequirementes')
        .matches(/[A-Z]+/, 'form.passRequirementes')
        .matches(/\d+/, 'form.passRequirementes'),
    passwordConfirmation: yup
        .string()
        .required('form.required')
        .oneOf([yup.ref('password')], 'form.equals'),
    accepted: yup.bool().oneOf([true], 'form.acceptTerms'),
});

function FirstTime() {
    const { companyConfig } = useConfigContext();
    const { colors } = useTheme();
    const { user, userAndTokenUpdate } = useUserAuthContext();
    const [isFetching, setIsFetching] = useState(false);

    const navigate = useNavigate();

    const toast = useFsToast();

    const { isLoading, data } = useQuery<Terms>({
        queryKey: ['terms'],
        queryFn: () =>
            fetchTermsOfUse(companyConfig ? companyConfig.client_token : ''),
    });

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            password: '',
            passwordConfirmation: '',
            accepted: false,
        },
    });

    function handleConfirm(data: FormData) {
        setIsFetching(true);
        fetchOptIn(data.password, data.passwordConfirmation, data.accepted)
            .then((response) => {
                setIsFetching(false);
                const optUser = user;
                if (optUser && !optUser.is_opt_in) {
                    optUser.is_opt_in = true;
                    userAndTokenUpdate(optUser);
                }
                navigate('/app/feed');
                toast({
                    title: t('form.alterPasswordSuccess'),
                    status: 'success',
                });
            })
            .catch(() => {
                setIsFetching(false);
                toast({
                    title: t('form.error'),
                    status: 'error',
                });
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${t('firstTime.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <VStack bgColor="gray.100" h="100vh" spacing="0" align="stretch">
                <Box
                    p="0"
                    px="4"
                    m="4"
                    h="100%"
                    overflowY="auto"
                    overflowX="hidden"
                    sx={{
                        '::-webkit-scrollbar': {
                            width: '2',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: lighten(0.2, colors.brand.primary),
                            height: '50%',
                            borderRadius: '20',
                        },
                        '::-webkit-scrollbar-track': {
                            background: colors.gray[50],
                            borderRadius: '20',
                            marginTop: '20',
                            marginBottom: '20',
                        },
                    }}
                >
                    {companyConfig?.assets.logo && (
                        <Center my="8">
                            <Image
                                src={companyConfig?.assets.logo}
                                height="100%"
                                maxHeight="60px"
                            />
                        </Center>
                    )}

                    <Text
                        as="h1"
                        fontSize="xl"
                        color="gray.600"
                        fontWeight="700"
                        textAlign="center"
                        mb="8"
                    >
                        <Translator path="translations.termsOfUse" />
                    </Text>
                    {isLoading ? (
                        <FsSkeletonText noOfLines={15} />
                    ) : (
                        <>
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: data ? data.text : '',
                                }}
                            />

                            <VStack
                                align="stretch"
                                spacing="5"
                                mt="5"
                                mb="20"
                                pt="5"
                                borderTop="solid 1px"
                                borderTopColor={colors.brand.primary}
                            >
                                <Box>
                                    <Controller
                                        control={control}
                                        name="accepted"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FsCheckbox
                                                label="Li e estou de acordo com esses termos de uso"
                                                onChange={onChange}
                                                isChecked={value}
                                                isInvalid={
                                                    errors.accepted
                                                        ? true
                                                        : false
                                                }
                                                error={errors.accepted}
                                            />
                                        )}
                                    />
                                </Box>

                                <Box>
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FsInputPassword
                                                label="Nova Senha"
                                                placeholder="Nova Senha"
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit((data) =>
                                                            handleConfirm(data)
                                                        )();
                                                    }
                                                }}
                                                value={value}
                                                error={errors.password}
                                            />
                                        )}
                                    />
                                </Box>

                                <Box>
                                    <Controller
                                        control={control}
                                        name="passwordConfirmation"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FsInputPassword
                                                label="Confime a nova senha"
                                                placeholder="Confirmação"
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit((data) =>
                                                            handleConfirm(data)
                                                        )();
                                                    }
                                                }}
                                                value={value}
                                                error={
                                                    errors.passwordConfirmation
                                                }
                                            />
                                        )}
                                    />
                                </Box>

                                <Box>
                                    <FsButton
                                        block={true}
                                        isLoading={isFetching}
                                        isDisabled={isFetching}
                                        onClick={handleSubmit((data) =>
                                            handleConfirm(data)
                                        )}
                                    >
                                        Aceito os termos de uso
                                    </FsButton>
                                </Box>
                            </VStack>
                        </>
                    )}
                </Box>
            </VStack>
        </>
    );
}

export default FirstTime;
