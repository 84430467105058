import { Box, Center, Heading, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';

type ErrorPageProps = {
    title?: string;
    description?: string;
};

function ErrorPage({ title, description }: ErrorPageProps) {
    return (
        <>
            <Helmet>
                <title>{`Erro`}</title>
            </Helmet>
            <Box w="100%" h="100%">
                <Center flexDirection="column" justifyContent="center" h="100%">
                    <Heading as="h1">
                        {title || t('errors.defaultErrorTitle')}
                    </Heading>
                    <Text>
                        {description || t('errors.defaultErrorDescription')}
                    </Text>
                </Center>
            </Box>
        </>
    );
}

export default ErrorPage;
