import {
    Box,
    Button,
    HStack,
    VStack,
    Text,
    useDisclosure,
    useTheme,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { MdLogout, MdMenu } from 'react-icons/md';
import { useUserAuthContext } from '../../context/userAuthContext';
import { api } from '../../services/api';
import { USERLASTCATEGORIESKEY } from '../../services/localStorageKeys';
import { getMenuIcon, getMenuTitle, getRoute } from '../../services/utils';
import FsDrawer from '../Fs/Drawer';
import MenuItem from '../MenuItem';
import Translator from '../Translator';
import MenuDrawerItem from '../MenuDrawerItem';

export async function loadCategories() {
    if (navigator.onLine) {
        return api.get<string[]>('client/categories').then((response) => {
            if (response.status !== 200) {
                const categories = localStorage.getItem(USERLASTCATEGORIESKEY);
                return categories ? (JSON.parse(categories) as string[]) : [];
            }
            localStorage.setItem(
                USERLASTCATEGORIESKEY,
                JSON.stringify(response.data)
            );
            return response.data;
        });
    } else {
        const categories = localStorage.getItem(USERLASTCATEGORIESKEY);
        return categories ? (JSON.parse(categories) as string[]) : [];
    }
}

function Menu() {
    const [showMenu, setShowMenu] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { colors } = useTheme();
    const { signOut } = useUserAuthContext();

    const { isLoading, data } = useQuery<string[]>({
        queryKey: ['categories'],
        queryFn: () => loadCategories(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        !isLoading && setShowMenu(true);
    }, [isLoading]);

    const categories = data ? data : [];
    const menuHiddenCategories = [
        ...categories.filter(
            (c) =>
                c !== 'NOTÍCIAS' &&
                c !== 'UNIDADE' &&
                c !== 'VÍDEOS' &&
                c !== 'TVONLINE'
        ),
        'FAVORITOS',
    ];
    const menuListOrderlyAvailable: string[] = [
        'NOTÍCIAS',
        'UNIDADE',
        'VÍDEOS',
        'TVONLINE',
    ];
    const menuList: string[] = menuListOrderlyAvailable.filter((category) => {
        return categories.find((c) => c === category) !== undefined;
    });

    const menuPessoalInfoList: string[] = ['PROFILE', 'CONTACT', 'TERMS'];

    return (
        <Box
            bg="white"
            w="100%"
            h="20"
            px="4"
            position="fixed"
            zIndex="banner"
            bottom={`${showMenu ? '0' : '-20'}`}
            left="0"
            transition="bottom 0.3s"
        >
            <HStack
                justifyContent="space-between"
                alignItems="center"
                align="stretch"
                h="100%"
            >
                <MenuItem
                    title={getMenuTitle('FEED')}
                    url={getRoute('FEED')}
                    Icon={getMenuIcon('FEED')}
                />

                {menuList.map((menuItem, idx) => {
                    return (
                        <MenuItem
                            key={idx}
                            title={getMenuTitle(menuItem)}
                            url={getRoute(menuItem)}
                            Icon={getMenuIcon(menuItem)}
                        />
                    );
                })}

                <Box
                    as="button"
                    onClick={onOpen}
                    display="flex"
                    flexDir="column"
                    alignItems="center"
                >
                    <MdMenu size="20px" color={colors.gray[200]} />
                    <Text fontSize="xs" color={'gray.300'} mt="2">
                        <Translator path="translations.menu.more" />
                    </Text>
                </Box>
            </HStack>

            <FsDrawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                drawerFooter={
                    <>
                        <Button
                            leftIcon={
                                <MdLogout size="15" color={colors.gray[200]} />
                            }
                            color="gray.200"
                            fontSize="xs"
                            bgColor="transparent"
                            _hover={{
                                bgColor: 'transparent',
                            }}
                            onClick={signOut}
                        >
                            <Translator path="translations.menu.logout" />
                        </Button>
                        <Text color="gray.200" fontSize="xs">
                            v{process.env.REACT_APP_VERSION}
                        </Text>
                    </>
                }
            >
                <VStack align="stretch">
                    <Box pt="6">
                        <Text color="gray.200" fontSize="md" fontWeight="bold">
                            <Translator path="translations.menu.moreContent" />
                        </Text>
                        <Box mt="2">
                            {menuHiddenCategories.map((category, idx) => (
                                <MenuDrawerItem
                                    key={idx}
                                    title={getMenuTitle(category)}
                                    url={getRoute(category)}
                                    Icon={getMenuIcon(category)}
                                    onClick={onClose}
                                />
                            ))}
                        </Box>
                    </Box>

                    <Box pt="6">
                        <Text color="gray.200" fontSize="md" fontWeight="bold">
                            <Translator path="translations.menu.pessoalInfo" />
                        </Text>
                        <Box mt="2">
                            {menuPessoalInfoList.map((menuItem, idx) => (
                                <MenuDrawerItem
                                    key={idx}
                                    title={getMenuTitle(menuItem)}
                                    url={getRoute(menuItem)}
                                    Icon={getMenuIcon(menuItem)}
                                    onClick={onClose}
                                />
                            ))}
                        </Box>
                    </Box>
                </VStack>
            </FsDrawer>
        </Box>
    );
}

export default Menu;
