import { Box, Image, Text, HStack, Button, VStack } from '@chakra-ui/react';
import { MdGetApp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import useStandAlone from '../../hooks/standAlone';
import Translator from '../Translator';

type HeaderProps = {
    installApp: (ignoretimeout: boolean) => void;
    abbleToInstall?: boolean;
};

function Header({ abbleToInstall, installApp }: HeaderProps) {
    const { companyConfig } = useConfigContext();

    const navigate = useNavigate();
    const showInstall = !useStandAlone() && abbleToInstall;

    return (
        <VStack spacing="0" align="stretch">
            {showInstall && (
                <Box bgColor="yellow.300">
                    <Button
                        onClick={() => {
                            installApp(true);
                        }}
                        bgColor="transparent"
                        _hover={{
                            bgColor: 'transparent',
                        }}
                        leftIcon={<MdGetApp />}
                        variant="solid"
                        fontSize="xs"
                    >
                        <Translator path="translations.installApp" />
                    </Button>
                </Box>
            )}

            <HStack
                spacing="0"
                align="stretch"
                alignItems="center"
                justifyContent="space-between"
                px="4"
                py="5"
            >
                <Text noOfLines={2} fontWeight="200" fontSize="20">
                    {companyConfig?.name}
                </Text>
                {companyConfig?.assets.icon && (
                    <Box as="button" onClick={() => navigate('/app/feed')}>
                        <Image
                            height="100%"
                            maxHeight="50"
                            src={companyConfig.assets.icon}
                        />
                    </Box>
                )}
            </HStack>
        </VStack>
    );
}

export default Header;
