import { Language } from '../../../types/translation';

const en: Language = {
    translations: {
        form: {
            required: 'This field is required',
            incorrect:
                'Incorrect information, make sure you typed the fields correctly',
            error: 'We had a problem, please try again later',
            removeAccount: 'We removed your account',
            alterAccountSuccess: 'De change your data',
            email: 'Enter an email',
            sendMessage: 'Your message was sent',
            equals: 'Passwords are not the same',
            passRequirementes:
                'The password must have at least one lowercase letter, one uppercase letter, and one number',
            sixCaracteres: 'Minimum of 6 characters',
            alterPasswordSuccess: 'Your password has been changed successfully',
            alterPasswordInvalid: 'Invalid current password',
            send: 'Send',
            addComment: 'Add a comment...',
            successComment: 'Your comment was sent',
            acceptTerms: 'You need to accept the terms of use',
        },
        login: {
            password: 'Password',
            login: 'Login',
            welcomeText: 'Get into your',
            account: 'Account',
            forgotPassword: "Don't remember your password?",
            createNow: 'Create now',
            dontHave: "Don't have an account?",
            termsOfUse: 'Terms of use',
        },
        search: {
            noResultsFound: 'No results found for "{{query}}".',
        },
        back: 'GO BACK',
        close: 'CLOSE',
        termsOfUse: 'Terms of Use',
        notHaveAccount: "Don't have an account?",
        installApp: 'Install app',
        forgotPassword: {
            title: 'Forgot password?',
            description:
                "Don't worry, we've sent you instructions to recover it!",
            button: 'Recover Password',
            dontHaveAcccount: "Don't have an account?",
            createNow: 'Create now',
        },
        feed: {
            Download: 'Downloads',
            feed: 'Feed',
            Link: 'Links',
            Podcast: 'Podcasts',
            Vídeo: 'Videos',
            Notícia: 'News',
            Unidade: 'Unit',
            TVOnline: 'Online TV',
            Favorito: 'Favorites',
            Busca: {
                title: 'Search',
                noResult: 'No results',
                result: 'Result',
                results: 'Results',
            },
        },
        types: {
            Download: 'Download',
            Link: 'Link',
            Podcast: 'Podcast',
            Vídeo: 'Video',
            Notícia: 'News',
        },
        interactive: {
            doFav: 'Favorite',
            favs: 'Favorites',
            fav: 'Favorite',
            doLike: 'Like',
            like: 'Like',
            likes: 'Likes',
            comment: 'Comment',
            comments: 'Comments',
            doComment: 'Comment',
        },
        menu: {
            more: 'more',
            profile: 'My Profile',
            sendMessage: 'Contact Us',
            terms: 'Terms of Use',
            logout: 'Logout',
            moreContent: 'MORE CONTENTS',
            pessoalInfo: 'PERSONAL INFORMATION',
        },
        profile: {
            title: 'My Profile',
            name: 'Full Name',
            email: 'E-mail',
            change: 'Change my password',
            save: 'Save',
            deleteAccount: 'Delete my account',
            description: 'Edit your information',
            atention: 'Attention',
            modalDescription: [
                'If you continue, your account and all data, including history and information, will be permanently deleted.',
                'Type "<strong>CONFIRM</strong>" to delete your account.',
            ],
            pass: 'CONFIRM',
            toExclude: 'to delete your account',
            typeIt: 'Type it',
            delete: 'Delete',
            cancel: 'Cancel',
        },
        sendMessage: {
            title: 'Contact Us',
            description:
                'The following message will be directed to a person responsible for your company, remember to add a contact form in the message if you want to get a response from her.',
            message: 'Message',
            send: 'Send Message',
            subject: 'Subject',
            help: 'Add a message to pass on to the person responsible for your company.',
            addMessage: 'Add message',
        },
        changePassword: {
            title: 'Change Password',
            description: 'Change your password',
            actual: 'Current Password',
            alter: 'Update password',
            confirm: 'Confirm the new password',
            new: 'New Password',
        },
        firstTime: {
            accept: 'I have read and agree to these terms of use',
            button: 'I accept the terms of use',
            confirmationPassword: 'Confirm the new password',
            confirmation: 'Confirmation',
            new: 'New Password',
            title: 'First time',
        },
        update: {
            haveAUpdate: 'There is an update available!',
            clickHere: 'Please click here to update',
        },
        errors: {
            domainTitle: 'Domain not found',
            domainDescription: 'unspecified customer not found',
            defaultErrorTitle: 'Ops....',
            defaultErrorDescription: 'Sorry, an unexpected error has occurred.',
        },
        tvonline: {
            description:
                'In the video above you can watch the Content of Corporate TV.',
        },
    },
};

export default en;
