import { BoxProps } from '@chakra-ui/react';
import FixedConfigPage from '../../components/FixedConfigPage';
import TermsOfUseComponent from '../../components/TermsOfUseComponent';

function TermsOfUsePage({ ...rest }: BoxProps) {
    return (
        <FixedConfigPage>
            <TermsOfUseComponent />
        </FixedConfigPage>
    );
}

export default TermsOfUsePage;
