import { Input, InputProps, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { FieldError } from 'react-hook-form';

type FsInputTextProps = InputProps & {
    label?: string;
    error?: FieldError;
};

function FsInputText({ label, error, ...rest }: FsInputTextProps) {
    return (
        <>
            {label && (
                <Text fontSize="sm" mb="1">
                    {label}
                </Text>
            )}
            <Input type="text" bgColor="white" {...rest} />
            {error?.message && (
                <Text color="red.300" fontSize="xs" mt="1">
                    {t(error.message)}
                </Text>
            )}
        </>
    );
}

export default FsInputText;
