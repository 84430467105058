import { IconType } from 'react-icons/lib';
import {
    MdDvr,
    MdFeed,
    MdInfo,
    MdLightbulbOutline,
    MdLink,
    MdMic,
    MdNewspaper,
    MdOutlineBuildCircle,
    MdOutlineDownloadForOffline,
    MdOutlineMap,
    MdPlayCircleOutline,
    MdStar,
} from 'react-icons/md';
import { FiHome } from 'react-icons/fi';
import { LASTPROMPTVIEWED } from './localStorageKeys';
import { t } from 'i18next';

export function getRoute(category: string) {
    let route = '/';
    switch (category) {
        case 'FEED':
            route = 'feed';
            break;
        case 'UNIDADE':
            route = 'unit';
            break;
        case 'LINKS':
        case 'Link':
            route = 'link';
            break;
        case 'NOTÍCIAS':
        case 'Notícia':
            route = 'news';
            break;
        case 'VÍDEOS':
        case 'Vídeo':
            route = 'video';
            break;
        case 'PODCASTS':
        case 'Podcast':
            route = 'podcast';
            break;
        case 'DOWNLOADS':
        case 'Download':
            route = 'download';
            break;
        case 'TVONLINE':
        case 'tvonline':
            route = 'tvonline';
            break;
        case 'TERMS':
            route = 'terms-of-use';
            break;
        case 'CONTACT':
            route = 'contact-us';
            break;
        case 'PROFILE':
            route = 'profile';
            break;
        case 'FAVORITOS':
            route = 'favorites';
            break;
        default:
            route = '';
            break;
    }
    return route;
}

export function getMenuTitle(category: string) {
    let title = '';
    switch (category) {
        case 'FEED':
            title = t('feed.feed');
            break;
        case 'UNIDADE':
            title = t('feed.Unidade');
            break;
        case 'LINKS':
            title = t('feed.Link');
            break;
        case 'NOTÍCIAS':
            title = t('feed.Notícia');
            break;
        case 'VÍDEOS':
            title = t('feed.Vídeo');
            break;
        case 'PODCASTS':
            title = t('feed.Podcast');
            break;
        case 'DOWNLOADS':
            title = t('feed.Download');
            break;
        case 'TVONLINE':
            title = t('feed.TVOnline');
            break;
        case 'TERMS':
            title = t('termsOfUse');
            break;
        case 'CONTACT':
            title = t('sendMessage.title');
            break;
        case 'PROFILE':
            title = t('profile.title');
            break;
        case 'FAVORITOS':
            title = t('feed.Favorito');
            break;
        default:
            title = '';
            break;
    }
    return title;
}

export function getMenuIcon(category: string) {
    let icon: IconType;
    switch (category) {
        case 'FEED':
            icon = FiHome;
            break;
        case 'UNIDADE':
            icon = MdOutlineMap;
            break;
        case 'LINKS':
            icon = MdLink;
            break;
        case 'NOTÍCIAS':
            icon = MdNewspaper;
            break;
        case 'VÍDEOS':
            icon = MdPlayCircleOutline;
            break;
        case 'PODCASTS':
            icon = MdMic;
            break;
        case 'DOWNLOADS':
            icon = MdOutlineDownloadForOffline;
            break;
        case 'TVONLINE':
            icon = MdDvr;
            break;
        case 'TERMS':
            icon = MdInfo;
            break;
        case 'CONTACT':
            icon = MdLightbulbOutline;
            break;
        case 'PROFILE':
            icon = MdOutlineBuildCircle;
            break;
        case 'FAVORITOS':
            icon = MdStar;
            break;
        default:
            icon = MdFeed;
            break;
    }
    return icon;
}

export function getSubDomain(hostname: string) {
    if (process.env.NODE_ENV === 'production' && !hostname.startsWith('10')) {
        let subdomain = hostname.split('.')[0];
        subdomain = subdomain === 'localhost' ? 'farolapp' : subdomain;
        return subdomain;
    } else {
        let subdomain = hostname.split('.')[0];
        subdomain =
            subdomain === 'localhost' || subdomain === '10'
                ? 'farolapp'
                : subdomain;
        return subdomain;
    }
}

export function getDomain(host: string) {
    if (host.match('localhost') || host.match('10.0.2.2')) {
        return `http://${host}/`;
    } else {
        return `https://${host}/`;
    }
}

export function handlePromptView(
    timeoutSeconds?: number,
    ignoreTimeout = false
) {
    if (ignoreTimeout) {
        return true;
    }

    const lastInLocalStorage = localStorage.getItem(LASTPROMPTVIEWED);

    if (!lastInLocalStorage) {
        localStorage.setItem(
            LASTPROMPTVIEWED,
            JSON.stringify(new Date().getTime())
        );
        return true;
    }

    const isAllowed = JSON.parse(lastInLocalStorage) <= new Date().getTime();

    if (timeoutSeconds !== undefined) {
        localStorage.setItem(
            LASTPROMPTVIEWED,
            JSON.stringify(new Date().getTime() + timeoutSeconds * 1000)
        );
    }

    return isAllowed;
}
