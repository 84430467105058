import { AxiosResponse } from 'axios';
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { api, authenticateUser } from '../services/api';
import {
    COMPANYCONFIGKEY,
    USERKEY,
    LEGACYUSERKEY,
} from '../services/localStorageKeys';
import { CompanyConfig } from '../types/contentTypes';

type UserAuthContextProviderProps = {
    children: ReactNode;
};

export type User = {
    id: number;
    client_id: number;
    user_token: string;
    name: string;
    username: string;
    email: string | null;
    groups: string[] | null;
    is_active: boolean;
    is_opt_in: boolean;
};

type LegacyUser = {
    id: number;
    id_client: number;
    token: string;
    nome: string;
    matricula: string;
    email: string | null;
    grupos: string;
    ativo: boolean;
    optin: boolean;
};

type UserAuthContextProps = {
    user: User | undefined;
    signIn: (
        username: string,
        password: string
    ) => Promise<AxiosResponse<User, any>>;
    signOut: () => void;
    userAndTokenUpdate: (user: User) => void;
    isLoading: boolean;
};

const UserContext = createContext<UserAuthContextProps>(
    {} as UserAuthContextProps
);

export function UserContextProvider({
    children,
}: UserAuthContextProviderProps) {
    const [user, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState(true);

    function getUserFromLocalStorage() {
        const user = localStorage.getItem(USERKEY);
        if (user) {
            return JSON.parse(user) as User;
        }
        return undefined;
    }

    function getLegacyUserFromLocalStorage() {
        const user = localStorage.getItem(LEGACYUSERKEY);
        if (user) {
            return JSON.parse(user) as LegacyUser;
        }
        return undefined;
    }

    function userAndTokenUpdate(user: User) {
        api.defaults.headers.common['user-token'] = user.user_token;
        user.is_opt_in && localStorage.setItem(USERKEY, JSON.stringify(user));
        setUser(user);
    }

    async function signIn(username: string, password: string) {
        const storageClientToken = localStorage.getItem(COMPANYCONFIGKEY);
        const companyConfig = storageClientToken
            ? (JSON.parse(storageClientToken) as CompanyConfig)
            : undefined;

        const authenticateResponse = await authenticateUser(
            companyConfig?.client_token ? companyConfig.client_token : '',
            username,
            password
        )
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });

        const newUser =
            authenticateResponse.status === 200
                ? authenticateResponse.data
                : undefined;

        if (newUser) {
            userAndTokenUpdate(newUser);
        }
        return authenticateResponse;
    }

    function signOut() {
        api.defaults.headers.common['user-token'] = '';
        localStorage.removeItem(USERKEY);
        setUser(undefined);

        if ('serviceWorker' in navigator) {
            caches.keys().then(function (cacheNames) {
                cacheNames.forEach(function (cacheName) {
                    caches.delete(cacheName);
                });
            });
        }
    }

    useEffect(() => {
        let user = getUserFromLocalStorage();

        if (!user) {
            const legacyUser = getLegacyUserFromLocalStorage();

            if (legacyUser) {
                user = {
                    client_id: legacyUser.id,
                    email: legacyUser.email,
                    groups: [legacyUser.grupos],
                    id: legacyUser.id,
                    is_active: legacyUser.ativo,
                    is_opt_in: legacyUser.optin,
                    name: legacyUser.nome,
                    user_token: legacyUser.token,
                    username: legacyUser.matricula,
                };
                localStorage.removeItem(LEGACYUSERKEY);
            }
        }
        user && userAndTokenUpdate(user);
        setIsLoading(false);
    }, []);

    return (
        <UserContext.Provider
            value={{ user, signIn, signOut, isLoading, userAndTokenUpdate }}
        >
            {children}
        </UserContext.Provider>
    );
}

export const useUserAuthContext = () => useContext(UserContext);
