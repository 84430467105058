import { AspectRatio, Box, Skeleton, SkeletonText } from '@chakra-ui/react';

function NewsSkeleton() {
    return (
        <Box width="100%" px="4">
            <AspectRatio ratio={16 / 9}>
                <Skeleton
                    startColor="gray.100"
                    endColor="gray.200"
                    height="100%"
                    width="100%"
                    borderRadius="6px"
                />
            </AspectRatio>
            <SkeletonText
                py="4"
                startColor="gray.100"
                endColor="gray.200"
                noOfLines={1}
                spacing={4}
                skeletonHeight="10px"
                width="50%"
                borderRadius="6px"
            />
            <SkeletonText
                py="4"
                startColor="gray.100"
                endColor="gray.200"
                noOfLines={20}
                spacing={4}
                skeletonHeight="10px"
                width="100%"
                borderRadius="6px"
            />
        </Box>
    );
}

export default NewsSkeleton;
