import { useToast, UseToastOptions } from '@chakra-ui/react';

type useFsToastProps = UseToastOptions;

function useFsToast() {
    const toast = useToast();

    const fsToast = ({ title, description, ...rest }: useFsToastProps) => {
        toast({
            title,
            description,
            position: 'top',
            variant: 'subtle',
            isClosable: true,
            ...rest,
        });
    };

    return fsToast;
}

export default useFsToast;
