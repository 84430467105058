import { useTranslation } from 'react-i18next';
import { LanguageKeys } from '../../types/translation';

type TranslatorProps = {
    path: LanguageKeys;
};
const Translator = ({ path }: TranslatorProps) => {
    const { t } = useTranslation();

    return <>{t(path.slice(13, path.length))}</>;
};

export default Translator;
