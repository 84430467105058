import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        brand: {
            primary: 'black',
            secondary: 'black',
            tertiary: 'black',
        },
        gray: {
            600: '#333333',
            400: '#545454',
            300: '#757575',
            200: '#AFAFAF',
            150: '#EEE',
            100: '#F6F6F6',
            50: '#D9DDEA',
        },
        orange: {
            100: '#FFF9E7',
        },
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
    fontSizes: {
        '2xs': '0.625rem',
    },
    Button: {
        variants: {
            solid: {
                bg: 'brand.primary',
                p: '16px',
            },
        },
    },
    components: {
        Heading: {
            defaultProps: {
                size: 'md',
            },
        },
    },
    styles: {
        global: {
            '#root': {
                width: '100%',
                height: '100%',
            },
            body: {
                width: '100%',
                height: '100%',
                backgroundColor: 'gray.100',
            },
            html: {
                width: '100%',
                height: '100%',
            },
        },
    },
});

export { theme };
