import { useEffect } from 'react';
import { Center, ChakraProvider, Spinner } from '@chakra-ui/react';
import { theme } from './theme';
import { useConfigContext } from './context/configContext';
import ErrorPage from './screens/ErrorPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppRoutes from './routes/AppRoutes';
import { useUserAuthContext } from './context/userAuthContext';
import { api, fetchManifest } from './services/api';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import { t } from 'i18next';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    const { companyConfig, isLoadingCompanyConfig, notFound } =
        useConfigContext();
    const { isLoading, signOut } = useUserAuthContext();

    useEffect(() => {
        async function setDynamicManifestAndIcons() {
            const manifest = await fetchManifest();

            const faviElement = document.createElement('link');
            faviElement.setAttribute('rel', 'icon');
            faviElement.setAttribute('href', manifest.icons[1].src);
            faviElement.setAttribute('id', 'icon');

            const appleTouchIconElement = document.createElement('link');
            appleTouchIconElement.setAttribute('rel', 'apple-touch-icon');
            appleTouchIconElement.setAttribute('href', manifest.icons[1].src);
            appleTouchIconElement.setAttribute('id', 'appleTouchIcon');

            const themeColorElement = document.createElement('meta');
            themeColorElement.setAttribute('name', 'theme-color');
            themeColorElement.setAttribute('content', manifest.theme_color);
            themeColorElement.setAttribute('id', 'themeColor');

            document.getElementById('icon')?.remove();
            document.getElementById('appleTouchIcon')?.remove();
            document.querySelector('themeColor')?.remove();

            const head = document.querySelector('head');
            head?.appendChild(faviElement);
            head?.appendChild(themeColorElement);
            head?.appendChild(appleTouchIconElement);
        }
        !isLoadingCompanyConfig && !notFound && setDynamicManifestAndIcons();
    }, [isLoadingCompanyConfig, notFound]);

    useEffect(() => {
        api.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.request.status === 401) {
                    signOut();
                }
                return Promise.reject(error);
            }
        );
    }, [signOut]);

    useEffect(() => {
        navigator.serviceWorker.addEventListener(
            'controllerchange',
            function () {
                window.location.reload();
            }
        );
    }, []);

    useEffect(() => {
        const handtalk = document.getElementById('handtalkLibScriptTag');

        if (!handtalk && companyConfig?.extra?.hand_talk) {
            const handtalkScript = document.createElement('script');
            handtalkScript.setAttribute('id', 'handtalkLibScriptTag');

            handtalkScript.innerHTML = `var ht = new HT({
           token: "${companyConfig?.extra?.hand_talk}",
           maxTextSize: 1000,
           exceptions: ['.noLibras'],
           pageSpeech: true,
           side: 'right',
         });`;
            const body = document.querySelector('body');
            body?.appendChild(handtalkScript);
        }
    }, [companyConfig]);

    theme.colors.brand.primary = companyConfig?.colors?.primary;
    theme.colors.brand.secondary = companyConfig?.colors?.secondary;
    theme.colors.brand.tertiary = companyConfig?.colors?.tertiary;

    const queryClient = new QueryClient({defaultOptions: {queries: {networkMode: 'always'}}});

    if (notFound) {
        return (
            <HelmetProvider>
                <ChakraProvider theme={theme}>
                    <ErrorPage
                        title={t('errors.domainTitle') || undefined}
                        description={t('errors.domainDescription') || undefined}
                    />
                </ChakraProvider>
            </HelmetProvider>
        );
    }

    return (
        <>
            {isLoadingCompanyConfig || isLoading ? (
                <ChakraProvider theme={theme}>
                    <Center h="100%">
                        <Spinner w="32" h="32" />
                    </Center>
                </ChakraProvider>
            ) : (
                <HelmetProvider>
                    <QueryClientProvider client={queryClient}>
                        <ChakraProvider theme={theme}>
                            <AppRoutes />
                        </ChakraProvider>
                    </QueryClientProvider>
                </HelmetProvider>
            )}
        </>
    );
}

export default App;
