import { Box, HStack, Text, VStack, Icon } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useState } from 'react';
import { contactUs } from '../../services/api';
import FixedConfigPage from '../../components/FixedConfigPage';
import { useNavigate } from 'react-router-dom';
import useFsToast from '../../components/Fs/Toast';
import FsInputText from '../../components/Fs/InputText';
import FsTextAreaComponent from '../../components/Fs/TextArea';
import { MdHelpOutline } from 'react-icons/md';
import FsButton from '../../components/Fs/Button';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import Translator from '../../components/Translator';

type FormData = {
    subject: string;
    message: string;
};

const schema = yup.object({
    subject: yup.string().required('form.required'),
    message: yup.string().required('form.required'),
});

export function ContactUs() {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const toast = useFsToast();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            subject: '',
            message: '',
        },
    });
    const { companyConfig } = useConfigContext();

    function handleConfirm(data: FormData) {
        setIsLoading(true);

        contactUs(data.subject, data.message)
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    toast({
                        description: t('form.sendMessage'),
                        status: 'success',
                    });
                    navigate(-1);
                }
            })
            .catch(() => {
                toast({
                    description: t('form.error'),
                    status: 'error',
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${t('sendMessage.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <FixedConfigPage>
                <Text
                    as="h1"
                    fontSize="3xl"
                    color="gray.600"
                    fontWeight="700"
                    textAlign="center"
                    mb="8"
                >
                    <Translator path="translations.sendMessage.title" />
                </Text>
                <Text>
                    <Translator path="translations.sendMessage.description" />
                </Text>

                <VStack align="stretch" spacing="5" mt="6">
                    <Box>
                        <Controller
                            control={control}
                            name="subject"
                            render={({ field: { onChange, value } }) => (
                                <FsInputText
                                    label={
                                        t('sendMessage.subject') || 'Subject'
                                    }
                                    onChange={onChange}
                                    value={value}
                                    placeholder={
                                        t('sendMessage.subject') || 'Subject'
                                    }
                                    error={errors.subject}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="message"
                            render={({ field: { onChange, value } }) => (
                                <FsTextAreaComponent
                                    label={
                                        t('sendMessage.message') || 'Message'
                                    }
                                    onChange={onChange}
                                    placeholder={
                                        t('sendMessage.addMessage') ||
                                        'Add Message'
                                    }
                                    value={value}
                                    resize="none"
                                    error={errors.message}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <HStack>
                            <Icon as={MdHelpOutline} color="gray.400" />
                            <Text fontSize="xs" color="gray.400">
                                <Translator path="translations.sendMessage.help" />
                            </Text>
                        </HStack>
                    </Box>
                    <Box>
                        <FsButton
                            block={true}
                            onClick={handleSubmit(handleConfirm)}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                        >
                            <Translator path="translations.sendMessage.send" />
                        </FsButton>
                    </Box>
                </VStack>
            </FixedConfigPage>
        </>
    );
}

export default ContactUs;
