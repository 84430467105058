import { VStack } from '@chakra-ui/react';
import { Content } from '../../types/contentTypes';
import CardComponent from '../CardComponent';

type ContentListComponentProps = {
    feedContent: Content[];
    onLastContentView?: () => void;
};

export function ContentListComponent({
    feedContent,
    onLastContentView,
}: ContentListComponentProps) {
    return (
        <VStack spacing="0" align="stretch">
            {feedContent?.map((content, index) => {
                if (feedContent.length === index + 1) {
                    return (
                        <CardComponent
                            key={content.id}
                            content={content}
                            onContentView={onLastContentView}
                        />
                    );
                }
                return <CardComponent key={content.id} content={content} />;
            })}
        </VStack>
    );
}

export default ContentListComponent;
