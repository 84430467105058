import { AspectRatio, Box, Divider, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import { getIntlRelativeDate } from '../../services/intl/intlFormats';
import { getRoute } from '../../services/utils';
import { Content } from '../../types/contentTypes';
import InteractiveButton from '../InteractiveButtons';
import TagComponent from '../TagComponent';
import Translator from '../Translator';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

type CardComponentProps = {
    content: Content;
    onContentView?: () => void;
};

export function CardComponent({ content, onContentView }: CardComponentProps) {
    const { ref, inView } = useInView();

    const imageUrl =
        content.type === 'Vídeo' || content.type === 'Podcast'
            ? content.assets.image
                ? content.assets.image
                : content.assets.video?.thumb
            : content.assets.image;
    const { companyConfig } = useConfigContext();

    useEffect(() => {
        inView && onContentView && onContentView();
    }, [inView, onContentView]);

    return (
        <Box ref={ref}>
            <Link
                to={`/app/${getRoute(content.type)}/${content.id}/${
                    content.slug
                }`}
            >
                <AspectRatio ratio={380 / 155}>
                    <Box
                        w="full"
                        h="full"
                        bgImage={imageUrl ? imageUrl : ''}
                        bgColor="blackAlpha.200"
                        bgRepeat="no-repeat"
                        bgSize="cover"
                        bgPosition="center"
                        borderRadius="lg"
                    >
                        <Box
                            w="full"
                            h="full"
                            bgGradient="linear-gradient(360deg, #040506 0%, rgba(0, 0, 0, 0) 100%)"
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                            flexDir="column"
                            textAlign="left"
                            color="white"
                            borderRadius="lg"
                            p="4"
                        >
                            {content.tags[0] && (
                                <TagComponent
                                    title={content.tags[0].toUpperCase()}
                                    key={content.tags[0].toUpperCase()}
                                />
                            )}
                        </Box>
                    </Box>
                </AspectRatio>

                <Box as="p" fontSize="sm" color="brand.primary" mt="4">
                    <Text as="span" fontWeight="bold">
                        <Translator
                            path={`translations.types.${content.type}`}
                        />
                    </Text>
                    <Text as="span" px="1">
                        •
                    </Text>
                    <Text as="span">
                        {getIntlRelativeDate(
                            companyConfig?.language,
                            new Date(content.published_at)
                        )}
                    </Text>
                </Box>

                <Text fontSize="xl" fontWeight="bold">
                    {content.title}
                </Text>
                <Text fontSize="md" color="gray.300">
                    {content.description_truncate}
                </Text>
            </Link>

            <Box mt="2">
                <InteractiveButton
                    liked={content.is_liked}
                    likes={content.likes_count}
                    favorited={content.is_favorited}
                    contentId={content.id}
                />
            </Box>

            <Divider my="6" />
        </Box>
    );
}

export default CardComponent;
