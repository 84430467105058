import { Box, BoxProps, Skeleton } from '@chakra-ui/react';

type LinkSkeletonProps = BoxProps;

function LinkSkeleton({ ...rest }: LinkSkeletonProps) {
    return (
        <Box overflow="hidden" px="4" {...rest}>
            {[...Array(5)].map((x, i) => {
                return (
                    <Box key={i}>
                        <Skeleton
                            startColor="gray.100"
                            endColor="gray.200"
                            height="70px"
                            width="100%"
                            borderRadius="6px"
                            mb="2"
                        />
                    </Box>
                );
            })}
        </Box>
    );
}

export default LinkSkeleton;
