import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translations from './locales';
import LanguageDetector from 'i18next-browser-languagedetector';

export function initI18N(language?: string) {
    const i18nConfig = {
        resources: translations,
        fallbackLng: 'pt-br',
        defaultNS: 'translations',
    };

    i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);
    language && i18n.changeLanguage(language);
}

export default i18n;
