import { Content } from '../../types/contentTypes';

import SimpleContentComponentProps from '../SimpleContentComponent';

type SimpleContentListComponentProps = {
    feedContent: Content[];
    onLastContentView?: () => void;
};

export function SimpleContentListComponent({
    feedContent,
    onLastContentView,
}: SimpleContentListComponentProps) {
    return (
        <>
            {feedContent?.map((content, index) => {
                if (feedContent.length === index + 1) {
                    return (
                        <SimpleContentComponentProps
                            key={content.id}
                            content={content}
                            onContentView={onLastContentView}
                        />
                    );
                }
                return (
                    <SimpleContentComponentProps
                        key={content.id}
                        content={content}
                    />
                );
            })}
        </>
    );
}

export default SimpleContentListComponent;
