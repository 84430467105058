import {
    Button,
    Icon,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    Text,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

type FsInputPasswordProps = Omit<InputProps, 'type'> & {
    label?: string;
    error?: FieldError;
};

function FsInputPassword({ label, error, ...rest }: FsInputPasswordProps) {
    const [showPassword, setShowPassword] = useState(false);
    const handleClick = () => setShowPassword(!showPassword);

    return (
        <>
            {label && (
                <Text fontSize="sm" mb="1">
                    {label}
                </Text>
            )}
            <InputGroup>
                <Input
                    type={showPassword ? 'text' : 'password'}
                    bgColor="white"
                    sx={{
                        '::-ms-reveal': {
                            display: 'none',
                        },
                    }}
                    {...rest}
                />
                <InputRightElement>
                    <Button
                        bgColor="transparent"
                        _hover={{
                            bgColor: 'transparent',
                        }}
                        onClick={handleClick}
                    >
                        {showPassword ? (
                            <Icon as={MdVisibilityOff} />
                        ) : (
                            <Icon as={MdVisibility} />
                        )}
                    </Button>
                </InputRightElement>
            </InputGroup>
            {error?.message && (
                <Text color="red.300" fontSize="xs" mt="1">
                    {t(error.message)}
                </Text>
            )}
        </>
    );
}

export default FsInputPassword;
