import { useEffect } from 'react';
import { useConfigContext } from '../../context/configContext';

function Client() {
    const { companyConfig } = useConfigContext();

    useEffect(() => {
        window.location.replace(
            `https://farolapp.com.br/cliente/login.aspx?subdomain=${companyConfig?.subdomain}`
        );
    }, [companyConfig?.subdomain]);

    return null;
}

export default Client;
