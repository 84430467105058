import { Box, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import { getIntlDate } from '../../services/intl/intlFormats';
import { getRoute } from '../../services/utils';
import { Content } from '../../types/contentTypes';
import TagComponent from '../TagComponent';

type DetachCardProps = {
    content: Content;
};

export function DetachCard({ content }: DetachCardProps) {
    const imageUrl =
        content.type === 'Vídeo'
            ? content.assets.image
                ? content.assets.image
                : content.assets.video?.thumb
            : content.assets.image;
    const { companyConfig } = useConfigContext();

    return (
        <Link
            to={`/app/${getRoute(content.type)}/${content.id}/${content.slug}`}
        >
            <Box
                w="full"
                h="full"
                bgImage={imageUrl ? imageUrl : ''}
                bgColor="blackAlpha.200"
                bgRepeat="no-repeat"
                bgSize="cover"
                bgPosition="center"
                borderRadius="lg"
            >
                <Box
                    w="full"
                    h="full"
                    bgGradient="linear-gradient(360deg, #040506 0%, rgba(0, 0, 0, 0) 100%)"
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    flexDir="column"
                    textAlign="left"
                    color="white"
                    borderRadius="lg"
                    p="4"
                >
                    {content.tags[0] && (
                        <TagComponent
                            title={content.tags[0].toUpperCase()}
                            key={content.tags[0].toUpperCase()}
                        />
                    )}
                    <Text noOfLines={2} fontSize="xl" fontWeight="bold">
                        {content.title}
                    </Text>
                    <Text noOfLines={1} fontSize="xs" color="whiteAlpha.700">
                        {getIntlDate(
                            companyConfig?.language,
                            new Date(content.published_at)
                        )}
                    </Text>
                </Box>
            </Box>
        </Link>
    );
}

export default DetachCard;
