import { AspectRatio, Box, BoxProps } from '@chakra-ui/react';
import FsSkeleton from '../Skeleton';
import FsSkeletonText from '../SkeletonText';

type FeedSkeletonProps = BoxProps;

function FeedSkeleton({ ...rest }: FeedSkeletonProps) {
    return (
        <Box overflow="hidden" px="4" {...rest}>
            <AspectRatio ratio={400 / 300} mb="8">
                <FsSkeleton />
            </AspectRatio>

            {[...Array(5)].map((x, i) => {
                return (
                    <Box key={i}>
                        <AspectRatio ratio={380 / 155} mb="2">
                            <FsSkeleton />
                        </AspectRatio>
                        <FsSkeletonText
                            noOfLines={Math.round(Math.random() * (5 - 2)) + 2}
                            skeletonHeight="2"
                            spacing="2"
                            mb="4"
                        />
                    </Box>
                );
            })}
        </Box>
    );
}

export default FeedSkeleton;
