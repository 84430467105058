import { Box, Center, Spinner } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import { fetchDownloadsFeedPage } from '../../services/api';
import { Content } from '../../types/contentTypes';
import SimpleContentListComponent from '../../components/SimpleContentListComponent';
import FeedHeader from '../../components/FeedHeader';
import LinkSkeleton from '../../components/Fs/Skeletons/LinkSkeleton';
import { t } from 'i18next';

function DownloadFeed() {
    const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useInfiniteQuery<Content[]>({
            queryKey: ['download-feed'],
            queryFn: ({ pageParam = 1 }) =>
                fetchDownloadsFeedPage(pageParam).then((content) => content),
            getNextPageParam: (lastPage, pages) =>
                lastPage.length === 25 ? pages.length + 1 : undefined,
        });
    const { companyConfig } = useConfigContext();

    const feedContent = data?.pages?.flatMap((page) => page);

    const ifLastContentWasViewd = () => {
        hasNextPage && fetchNextPage();
    };

    return (
        <>
            <Helmet>
                <title>{`${t('feed.Download')}  - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>

            <FeedHeader title={t('feed.Download')} />

            {isLoading === true ? (
                <LinkSkeleton />
            ) : (
                <Box px="4">
                    {feedContent && (
                        <SimpleContentListComponent
                            feedContent={feedContent}
                            onLastContentView={ifLastContentWasViewd}
                        />
                    )}
                </Box>
            )}
            {isFetchingNextPage && (
                <Box position="fixed" bottom="90px" w="full">
                    <Center>
                        <Spinner />
                    </Center>
                </Box>
            )}
        </>
    );
}

export default DownloadFeed;
