import { Heading } from '@chakra-ui/react';
import {
    useLoaderData,
    LoaderFunctionArgs,
    Params,
    defer,
    Await,
} from 'react-router-dom';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import { fetchContent, fetchComments } from '../../services/api';
import { Comment, Content } from '../../types/contentTypes';
import ContentComponent from '../../components/ContentComponent';
import VideoSkeleton from '../../components/Fs/Skeletons/VideoSkeleton';
import { t } from 'i18next';

interface ContentProps extends LoaderFunctionArgs {
    params: Params<'contentId' | 'slug'>;
}

export async function videoLoader({ params }: ContentProps) {
    const contentPromise = fetchContent(Number(params.contentId));
    const commentsPromise = fetchComments(Number(params.contentId));
    return defer({
        content: contentPromise,
        comments: commentsPromise,
    });
}

function Video() {
    const { content, comments } = useLoaderData() as {
        content: Promise<Content>;
        comments: Promise<Comment[]>;
    };
    const { companyConfig } = useConfigContext();

    return (
        <>
            <Helmet>
                <title>{`${t('types.Vídeo')} - ${companyConfig?.name}`}</title>
            </Helmet>
            <Suspense fallback={<VideoSkeleton />}>
                <Await
                    resolve={content}
                    errorElement={<Heading>Erro ao carregar o arquivo</Heading>}
                >
                    {(content: Content) => (
                        <>
                            <Helmet>
                                <title>{`${content.title} - ${t(
                                    'types.Vídeo'
                                )} - ${companyConfig?.name}`}</title>
                            </Helmet>
                            <ContentComponent
                                content={content}
                                commentsPromise={comments}
                            />
                        </>
                    )}
                </Await>
            </Suspense>
        </>
    );
}

export default Video;
