import { Navigate } from 'react-router-dom';
import { useUserAuthContext } from '../../context/userAuthContext';
import ScrollToTop from '../../services/scrollToTop';

type ProtectedRouteProps = {
    children: JSX.Element;
    isFirstTime?: boolean;
};

export const ProtectedRoute = ({
    children,
    isFirstTime = false,
}: ProtectedRouteProps) => {
    const { user } = useUserAuthContext();

    const ignoreTerms = user?.is_opt_in;

    if (!user?.user_token) {
        return <Navigate to="/app/login" />;
    } else if (!isFirstTime && !ignoreTerms) {
        return <Navigate to="/app/first-time" />;
    }

    return (
        <>
            <ScrollToTop />
            {children}
        </>
    );
};
