import { AspectRatio, Box, useTheme } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper';
import DetachCard from '../DetachCard';
import { Content } from '../../types/contentTypes';

type DetachListComponentProps = {
    detachContent: Content[];
};

export function DetachListComponent({
    detachContent,
}: DetachListComponentProps) {
    const { colors } = useTheme();

    return (
        <Swiper
            pagination={{
                clickable: true,
                el: '.elNavigationBottom',
            }}
            navigation={true}
            slidesPerView={1}
            spaceBetween={30}
            modules={[Pagination, Navigation]}
            className="detachSwiperGallery"
            style={{
                //@ts-ignore
                '--swiper-pagination-color': colors.brand.primary,
                '--swiper-pagination-bullet-inactive-color':
                    colors.brand.primary,
                '--swiper-pagination-bullet-inactive-opacity': 0.3,
                borderRadius: '6px',
                marginBottom: '32px',
            }}
        >
            {detachContent &&
                detachContent.length > 0 &&
                detachContent.map((content) => (
                    <SwiperSlide key={content.id}>
                        <AspectRatio ratio={400 / 300} mx="4">
                            <DetachCard content={content} />
                        </AspectRatio>
                    </SwiperSlide>
                ))}
            <Box
                className="elNavigationBottom"
                display="flex"
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                mt="4"
                gap="2"
            />
        </Swiper>
    );
}

export default DetachListComponentProps;
