import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Text,
    useDisclosure,
    VStack,
    Button,
    AlertDialogFooter,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { changeProfile, removeMyData } from '../../services/api';
import { useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { MdArrowRightAlt } from 'react-icons/md';
import { useUserAuthContext } from '../../context/userAuthContext';
import * as yup from 'yup';
import FixedConfigPage from '../../components/FixedConfigPage';
import { useNavigate } from 'react-router-dom';
import useFsToast from '../../components/Fs/Toast';
import FsInputText from '../../components/Fs/InputText';
import FsButton from '../../components/Fs/Button';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { useConfigContext } from '../../context/configContext';
import Translator from '../../components/Translator';

type FormData = {
    name: string;
    email: string;
};

const schema = yup.object({
    name: yup.string().required('form.required'),
    email: yup.string().required('form.required').email('form.email'),
});

export function Profile() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [textConfirmation, setTextConfirmation] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { user, userAndTokenUpdate } = useUserAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const toast = useFsToast();

    const cancelRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: user ? user.name : '',
            email: user ? (user.email ? user.email : '') : '',
        },
    });

    const { signOut } = useUserAuthContext();
    const { companyConfig } = useConfigContext();

    function handleConfirm(data: FormData) {
        setIsLoading(true);
        user &&
            changeProfile(user.username, data.name, data.email)
                .then(() => {
                    setIsLoading(false);
                    const newUser = user;
                    newUser.name = data.name;
                    newUser.email = data.email;
                    userAndTokenUpdate(newUser);
                    toast({
                        description: t('form.alterAccountSuccess'),
                        status: 'success',
                    });
                })
                .catch((error: AxiosError) => {
                    setIsLoading(false);
                    if (error.response?.status === 422) {
                        toast({
                            description: t('form.incorrect'),
                            status: 'warning',
                        });
                    } else {
                        toast({
                            description: t('form.error'),
                            status: 'error',
                        });
                    }
                });
    }
    function handleDelete() {
        const passConfirmation = t('profile.pass') || 'CONFIRAMR';
        if (textConfirmation !== passConfirmation) {
            setErrorMessage(
                t('form.incorrect') || 'make sure you typed it correctly'
            );
        } else {
            setIsLoading(true);
            removeMyData(true).then((response) => {
                if (response.status === 200) {
                    signOut();
                    toast({
                        description: t('form.removeAccount'),
                        status: 'success',
                    });
                } else {
                    onClose();
                    toast({
                        description: t('form.error'),
                        status: 'error',
                    });
                }

                setIsLoading(false);
            });
        }
    }

    function handleChangePassword() {
        navigate('/app/change-password');
    }

    return (
        <>
            <Helmet>
                <title>{`${t('profile.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <FixedConfigPage>
                <Text
                    as="h1"
                    fontSize="3xl"
                    color="gray.600"
                    fontWeight="700"
                    textAlign="center"
                    mb="8"
                >
                    <Translator path="translations.profile.title" />
                </Text>
                <Text>
                    <Translator path="translations.profile.description" />
                </Text>

                <VStack align="stretch" spacing="5" mt="6">
                    <Box>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, value } }) => (
                                <FsInputText
                                    label={t('profile.name') || 'Name'}
                                    placeholder={t('profile.name') || 'Name'}
                                    onChange={onChange}
                                    value={value}
                                    error={errors.name}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="email"
                            render={({ field: { onChange, value } }) => (
                                <FsInputText
                                    label={t('profile.email') || 'E-mail'}
                                    placeholder={t('profile.email') || 'E-mail'}
                                    onChange={onChange}
                                    value={value}
                                    type="email"
                                    error={errors.email}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <FsButton
                            onClick={handleChangePassword}
                            block={true}
                            bgColor="transparent"
                            border="solid 1px"
                            borderColor="gray.200"
                            color="gray.300"
                            _hover={{
                                backgroundColor: 'gray.50',
                            }}
                            _active={{
                                backgroundColor: 'gray.200',
                            }}
                        >
                            <Translator path="translations.profile.change" />
                        </FsButton>
                    </Box>
                    <Box>
                        <FsButton
                            onClick={handleSubmit(handleConfirm)}
                            block={true}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                        >
                            <Translator path="translations.profile.save" />
                        </FsButton>
                    </Box>
                    <Box>
                        <FsButton
                            onClick={onOpen}
                            bgColor="#F34141"
                            rightIcon={<MdArrowRightAlt />}
                            _hover={{
                                backgroundColor: 'red.300',
                            }}
                            _active={{
                                backgroundColor: 'red.400',
                            }}
                        >
                            <Translator path="translations.profile.deleteAccount" />
                        </FsButton>
                    </Box>
                </VStack>
            </FixedConfigPage>

            <AlertDialog
                isOpen={isOpen}
                onClose={onClose}
                leastDestructiveRef={cancelRef}
                isCentered
                size="sm"
            >
                <AlertDialogOverlay>
                    <AlertDialogContent mx="4">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            <Translator path="translations.profile.atention" />
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <VStack>
                                <Text mb="2">
                                    {t('profile.modalDescription.0')}
                                </Text>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: t('profile.modalDescription.1'),
                                    }}
                                />
                                <FsInputText
                                    value={textConfirmation}
                                    onChange={(event) =>
                                        setTextConfirmation(event.target.value)
                                    }
                                />
                                {errorMessage && (
                                    <Box>
                                        <Alert status="warning" fontSize="xs">
                                            <AlertIcon />
                                            <AlertDescription>
                                                {errorMessage}
                                            </AlertDescription>
                                        </Alert>
                                    </Box>
                                )}
                            </VStack>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button
                                ref={cancelRef}
                                onClick={() => {
                                    setTextConfirmation('');
                                    setErrorMessage('');
                                    onClose();
                                }}
                            >
                                <Translator path="translations.profile.cancel" />
                            </Button>
                            <Button
                                onClick={handleDelete}
                                isLoading={isLoading}
                                isActive={isLoading}
                                colorScheme="red"
                                ml={3}
                            >
                                <Translator path="translations.profile.delete" />
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default Profile;
