import { Button, ButtonProps, useTheme } from '@chakra-ui/react';
import { lighten } from 'polished';

type FsButtonProps = ButtonProps & {
    children: React.ReactNode;
    block?: boolean;
};

function FsButton({ children, block = false, ...rest }: FsButtonProps) {
    const { colors } = useTheme();

    return (
        <Button
            bgColor="brand.primary"
            color="white"
            w={block ? '100%' : ''}
            _hover={{
                backgroundColor: lighten(0.1, colors.brand.primary),
            }}
            _active={{
                backgroundColor: lighten(0.2, colors.brand.primary),
            }}
            _loading={{
                color: 'white',
            }}
            {...rest}
        >
            {children}
        </Button>
    );
}

export default FsButton;
