import { AspectRatio, Box } from '@chakra-ui/react';
import { Video } from '../../types/contentTypes';
import MuxVideo from '@mux/mux-video-react';

type TagProps = {
    video: Video;
};

function Player({ video }: TagProps) {
    const isEmbed = video.is_embed;

    return (
        <Box width="100%" maxH="100%">
            {isEmbed === false && (
                <AspectRatio maxW="100%" ratio={16 / 9}>
                    <MuxVideo src={video.url} controls autoPlay />
                </AspectRatio>
            )}

            {isEmbed === true && (
                <AspectRatio maxW="100%" ratio={16 / 9}>
                    <iframe
                        title="embed-video-player"
                        src={video.url}
                        width="100%"
                        allowFullScreen
                    />
                </AspectRatio>
            )}
        </Box>
    );
}

export default Player;
