import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import ReactGA from 'react-ga4';

type SendPageViewProps = {
    children: JSX.Element;
};

export const SendPageView = ({ children }: SendPageViewProps) => {
    const { companyConfig } = useConfigContext();
    const location = useLocation();

    useEffect(() => {
        if (companyConfig?.extra?.google_analytics) {
            ReactGA.initialize(companyConfig.extra.google_analytics);
        }
    }, [companyConfig]);

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
        });
    }, [location]);

    return children;
};
