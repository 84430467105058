import OneSignal from 'react-onesignal';

let isLoading = false;

type RunOneSignalProps = {
    appID: string;
    localhost: boolean;
    setInitialized: (state: boolean) => void;
};

export default async function runOneSignal({
    appID,
    localhost,
    setInitialized,
}: RunOneSignalProps) {
    const id = localhost ? '3e198021-2a00-4a4e-9ded-f70213dd1249' : appID;
    isLoading === false &&
        OneSignal.init({
            appId: id,
        }).then(() => {
            setInitialized(true);
            OneSignal.showSlidedownPrompt();
        });
    isLoading = true;
}
