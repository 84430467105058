import {
    Modal,
    ModalBody,
    ModalContent,
    ModalContentProps,
    ModalHeader,
    ModalOverlay,
    ModalProps,
} from '@chakra-ui/react';

type FsModalProps = ModalProps & {
    headerTitle?: string;
    modalContentProps?: ModalContentProps;
};

function FsModal({ headerTitle, modalContentProps, ...rest }: FsModalProps) {
    return (
        <Modal {...rest}>
            <ModalOverlay />
            <ModalContent {...modalContentProps}>
                {headerTitle && <ModalHeader>{headerTitle}</ModalHeader>}
                <ModalBody>{rest.children}</ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default FsModal;
