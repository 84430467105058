import {
    Box,
    Center,
    Image,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useConfigContext } from '../../context/configContext';
import { useUserAuthContext } from '../../context/userAuthContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import Translator from '../../components/Translator';
import * as yup from 'yup';
import FsButton from '../../components/Fs/Button';
import FsInputText from '../../components/Fs/InputText';
import FsInputPassword from '../../components/Fs/InputPassword';
import FsDrawer from '../../components/Fs/Drawer';
import TermsOfUseComponent from '../../components/TermsOfUseComponent';
import NotHaveRegistrationComponent from '../../components/NotHaveRegistrationComponent';
import ForgotPasswordComponent from '../../components/ForgotPasswordComponent';
import useFsToast from '../../components/Fs/Toast';

type ModalContent = 'termsOfUse' | 'notHaveRegistration' | 'forgotPassword';

type FormData = {
    login: string;
    password: string;
};

const schema = yup.object({
    login: yup.string().required('form.required'),
    password: yup.string().required('form.required'),
});

function Login() {
    const [drawerContent, setDrawerContent] = useState<ModalContent>();
    const [isLoading, setIsLoading] = useState(false);
    const { companyConfig } = useConfigContext();
    const { signIn } = useUserAuthContext();
    const { isOpen, onClose, onOpen } = useDisclosure();

    const toast = useFsToast();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            login: '',
            password: '',
        },
    });

    function openNoRegistration() {
        setDrawerContent('notHaveRegistration');
        onOpen();
    }

    function openTermsOfUse() {
        setDrawerContent('termsOfUse');
        onOpen();
    }

    function openForgotPassword() {
        setDrawerContent('forgotPassword');
        onOpen();
    }

    async function handleLogin(data: FormData) {
        setIsLoading(true);
        signIn(data.login, data.password)
            .then((response) => {
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response?.status === 401) {
                    toast({
                        description: t('form.incorrect'),
                        status: 'warning',
                    });
                } else {
                    toast({
                        description: t('form.error'),
                        status: 'error',
                    });
                }
            });
    }
    const backgound = companyConfig?.assets.background
        ? companyConfig.assets.background
        : '';
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{`Login - ${companyConfig?.name}`}</title>
            </Helmet>
            <Box w="100vw" h="100vh" display="flex" flexDirection="column">
                <Box flex="1 1 0%;">
                    <VStack
                        bgColor="gray.100"
                        h="100%"
                        spacing="0"
                        align="stretch"
                    >
                        <Box
                            h="35%"
                            bgImage={backgound}
                            bgRepeat="no-repeat"
                            bgSize="cover"
                            bgPosition="center"
                        >
                            <Box
                                h="100%"
                                bgGradient={
                                    backgound === ''
                                        ? ''
                                        : 'linear(to-b, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.5) 100%)'
                                }
                                display="flex"
                                flexDir="column"
                                justifyContent="space-between"
                                p="8"
                            >
                                <Text
                                    color={backgound === '' ? '' : 'white'}
                                    fontWeight="600"
                                    fontSize="2xl"
                                    lineHeight="120%"
                                >
                                    <Translator path="translations.login.welcomeText" />
                                    <br />
                                    <Translator path="translations.login.account" />
                                </Text>
                                {companyConfig?.assets.logo && (
                                    <Center maxH="60px">
                                        <Image
                                            src={companyConfig?.assets.logo}
                                            h="100%"
                                            className="noLibras"
                                        />
                                    </Center>
                                )}
                            </Box>
                        </Box>

                        <Box h="65%" p="8">
                            <VStack align="stretch" spacing="5">
                                <Box>
                                    <Controller
                                        control={control}
                                        name="login"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FsInputText
                                                label={
                                                    companyConfig?.label_login ||
                                                    'Login'
                                                }
                                                placeholder={
                                                    companyConfig?.label_login ||
                                                    'Login'
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit((data) =>
                                                            handleLogin(data)
                                                        )();
                                                    }
                                                }}
                                                value={value}
                                                error={errors.login}
                                            />
                                        )}
                                    />
                                </Box>

                                <Box>
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <FsInputPassword
                                                label={
                                                    t('login.password') ||
                                                    'Password'
                                                }
                                                placeholder={
                                                    t('login.password') ||
                                                    'Password'
                                                }
                                                onChange={onChange}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit((data) =>
                                                            handleLogin(data)
                                                        )();
                                                    }
                                                }}
                                                value={value}
                                                error={errors.password}
                                            />
                                        )}
                                    />
                                </Box>

                                <Box textAlign="right">
                                    <Text
                                        as="button"
                                        fontSize="sm"
                                        fontWeight="extrabold"
                                        onClick={openForgotPassword}
                                    >
                                        <Translator path="translations.login.forgotPassword" />
                                    </Text>
                                </Box>

                                <Box>
                                    <FsButton
                                        block={true}
                                        isLoading={isLoading}
                                        isDisabled={isLoading}
                                        onClick={handleSubmit((data) =>
                                            handleLogin(data)
                                        )}
                                    >
                                        <Translator path="translations.login.login" />
                                    </FsButton>
                                </Box>

                                <Box textAlign="center">
                                    <Text
                                        as="button"
                                        fontSize="xs"
                                        onClick={openNoRegistration}
                                    >
                                        <Translator path="translations.login.dontHave" />{' '}
                                        <strong>
                                            <Translator path="translations.login.createNow" />
                                        </strong>
                                    </Text>
                                </Box>

                                <Box textAlign="center">
                                    <Text
                                        as="button"
                                        fontSize="xs"
                                        textDecoration="underline"
                                        _hover={{
                                            textDecoration: 'none',
                                        }}
                                        onClick={openTermsOfUse}
                                    >
                                        <Translator path="translations.login.termsOfUse" />
                                    </Text>
                                </Box>
                            </VStack>
                        </Box>
                    </VStack>
                </Box>
                <Box as="footer" flex="0 1 0%">
                    <Text color="gray.200" fontSize="xs" align="center">
                        v{process.env.REACT_APP_VERSION}
                    </Text>
                </Box>
            </Box>
            <FsDrawer
                onClose={onClose}
                isOpen={isOpen}
                size="full"
                autoFocus={false}
                logo={companyConfig?.assets.logo}
            >
                {drawerContent === 'termsOfUse' && <TermsOfUseComponent />}
                {drawerContent === 'notHaveRegistration' && (
                    <NotHaveRegistrationComponent />
                )}
                {drawerContent === 'forgotPassword' && (
                    <ForgotPasswordComponent
                        notHaveAccount={openNoRegistration}
                    />
                )}
            </FsDrawer>
        </>
    );
}

export default Login;
