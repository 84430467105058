import ptBrTranslations from './pt-br';
import enUsTranslations from './en';
import esTranslations from './es';

const languages = {
    'pt-BR': ptBrTranslations,
    en: enUsTranslations,
    es: esTranslations,
};

export default languages;
