import { Language } from './../../../types/translation';
const ptBr: Language = {
    translations: {
        form: {
            required: 'Esse campo é obrigatório',
            incorrect:
                'Informações incorretas, verifique se digitou os campos corretamente',
            error: 'Tivemos um problema, tente novamente mais tarde',
            removeAccount: 'Removemos a sua conta',
            alterAccountSuccess: 'Alteramos os seus dados',
            email: 'Digite um e-mail',
            sendMessage: 'Sua mensagem foi enviada',
            equals: 'A confirmação deve ser igual',
            passRequirementes:
                'A senha deve ter ao menos uma letra minúscula, uma maiúscula e um número',
            sixCaracteres: 'Mínimo de 6 caracteres',
            alterPasswordSuccess: 'Sua senha foi alterada com sucesso',
            alterPasswordInvalid: 'Senha atual inválida',
            send: 'Enviar',
            addComment: 'Adicionar um comentário...',
            successComment: 'Seu comentário foi enviado',
            acceptTerms: 'Você precisa aceitar os termos de uso',
        },
        login: {
            password: 'Senha',
            login: 'Entrar',
            welcomeText: 'Entre na sua',
            account: 'Conta',
            forgotPassword: 'Não lembra a sua senha?',
            createNow: 'Crie agora',
            dontHave: 'Não possui uma conta?',
            termsOfUse: 'Termos de uso',
        },
        search: {
            noResultsFound: 'Não foi encontrado resultados para "{{query}}".',
        },
        back: 'VOLTAR',
        close: 'FECHAR',
        termsOfUse: 'Termos de Uso',
        notHaveAccount: 'Não possui conta?',
        installApp: 'Instalar app',
        forgotPassword: {
            title: 'Esqueceu a senha?',
            description:
                'Não se preocupe, enviamos instruções para você recupera-la!',
            button: 'Recuperar Senha',
            dontHaveAcccount: 'Não possui uma conta?',
            createNow: 'Crie agora',
        },
        feed: {
            Download: 'Downloads',
            feed: 'Feed',
            Link: 'Links',
            Podcast: 'Podcasts',
            Vídeo: 'Vídeos',
            Notícia: 'Notícias',
            Unidade: 'Unidade',
            TVOnline: 'TV Online',
            Favorito: 'Favoritos',
            Busca: {
                title: 'Busca',
                noResult: 'Sem resultado',
                result: 'Resultado',
                results: 'Resultados',
            },
        },
        types: {
            Download: 'Download',
            Link: 'Link',
            Podcast: 'Podcast',
            Vídeo: 'Vídeo',
            Notícia: 'Notícia',
        },
        interactive: {
            doFav: 'Favoritar',
            favs: 'Favoritos',
            fav: 'Favorito',
            doLike: 'Curtir',
            like: 'Curtida',
            likes: 'Curtidas',
            comment: 'Comentário',
            comments: 'Comentários',
            doComment: 'Comentar',
        },
        menu: {
            more: 'mais',
            profile: 'Meu Perfil',
            sendMessage: 'FaleConosco',
            terms: 'Termos de Uso',
            logout: 'Logout',
            moreContent: 'MAIS CONTEÚDOS',
            pessoalInfo: 'INFORMAÇÕES PESSOAIS',
        },
        profile: {
            title: 'Meu Perfil',
            name: 'Nome Completo',
            email: 'E-mail',
            change: 'Trocar minha senha',
            save: 'Salvar',
            deleteAccount: 'Deletar minha conta',
            description: 'Edite suas informações',
            atention: 'Atenção',
            modalDescription: [
                'Caso continue, sua conta e todos os dados, incluindo histórico e informações serão deletadas definitivamente.',
                'Digite "<strong>CONFIRMAR</strong>" para excluir sua conta.',
            ],
            pass: 'CONFIRMAR',
            toExclude: 'para excluir sua conta',
            typeIt: 'Digite',
            delete: 'Deletar',
            cancel: 'Cancelar',
        },
        sendMessage: {
            title: 'Fale Conosco',
            description:
                'A seguinte mensagem será direcionada para um responsável da sua empresa, lembre de adicionar uma forma de contato na mensagem se quiser ter um retorno dela.',
            message: 'Mensagem',
            send: 'Enviar mensagem',
            subject: 'Assunto',
            help: 'Adicione uma mensagem para passar ao responsável da sua empresa.',
            addMessage: 'Adicionar mensagem',
        },
        changePassword: {
            title: 'Trocar Senha',
            description: 'Troque a sua senha',
            actual: 'Senha Atual',
            alter: 'Atualizar senha',
            confirm: 'Confirme a nova senha',
            new: 'Nova senha',
        },
        firstTime: {
            accept: 'Li e estou de acordo com esses termos de uso',
            button: 'Aceito os termos de uso',
            confirmationPassword: 'Confirme a nova senha',
            confirmation: 'Confirmação',
            new: 'Nova Senha',
            title: 'Primeira vez',
        },
        update: {
            haveAUpdate: 'Há uma atualização disponível!',
            clickHere: 'Clique aqui para atualizar',
        },
        errors: {
            domainTitle: 'Dominio não encontrado',
            domainDescription: 'cliente especificado não encontrado',
            defaultErrorTitle: 'Ops....',
            defaultErrorDescription: 'Desculpe, ocorreu um erro inesperado.',
        },
        tvonline: {
            description:
                'No vídeo acima você poderá assistir o conteúdo da TV Corporativa.',
        },
    },
};

export default ptBr;
