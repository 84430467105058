import { Text, useTheme, BoxProps, Button, Box } from '@chakra-ui/react';
import {
    favorite,
    fetchInteractions,
    like,
    unfavorite,
    unlike,
} from '../../services/api';
import {
    MdStar,
    MdStarOutline,
    MdFavorite,
    MdFavoriteBorder,
    MdOutlineModeComment,
} from 'react-icons/md';
import { useEffect, useState } from 'react';
import { Comment, Interactions } from '../../types/contentTypes';
import { t } from 'i18next';

type InteractiveButtonTypes = BoxProps & {
    contentId: number;
    likes?: number;
    liked?: boolean;
    favorited?: boolean;
    comments?: undefined | Comment[];
    onClickComments?: () => void;
};

let favoriteController: undefined | AbortController;
let likeController: undefined | AbortController;

export function InteractiveButton({
    contentId,
    comments,
    onClickComments,
}: InteractiveButtonTypes) {
    const [interactions, setInteractions] = useState<Interactions>();

    const { colors } = useTheme();

    function setNewInteractions({
        favorited = interactions?.favorited,
        liked = interactions?.liked,
        likes_count = interactions?.likes_count,
        viewed = interactions?.viewed,
    }) {
        setInteractions({
            favorited: favorited ? favorited : false,
            liked: liked ? liked : false,
            likes_count: likes_count ? likes_count : 0,
            viewed: viewed ? viewed : false,
        });
    }

    function handleLikeClick() {
        likeController?.abort();
        likeController = new AbortController();
        const signalLike = likeController.signal;

        if (interactions) {
            setNewInteractions({
                liked: !interactions?.liked,
                likes_count: interactions.liked
                    ? interactions.likes_count - 1
                    : interactions.likes_count + 1,
            });
        }

        interactions?.liked
            ? unlike(contentId, signalLike).then((likes) =>
                  setNewInteractions({
                      likes_count: likes.likes_count,
                      liked: false,
                  })
              )
            : like(contentId, signalLike).then((likes) =>
                  setNewInteractions({
                      likes_count: likes.likes_count,
                      liked: true,
                  })
              );
    }

    const handleFavoriteClick = () => {
        favoriteController?.abort();
        favoriteController = new AbortController();
        const signalFavorite = favoriteController.signal;

        interactions?.favorited
            ? unfavorite(contentId, signalFavorite)
            : favorite(contentId, signalFavorite);
        if (interactions) {
            if (interactions) {
                setNewInteractions({
                    favorited: !interactions?.favorited,
                });
            }
        }
    };

    function handleCommentClick() {
        onClickComments && onClickComments();
        fetchInteractions(contentId).then(setInteractions);
    }

    useEffect(() => {
        fetchInteractions(contentId).then(setInteractions);
    }, [contentId]);

    return (
        <Box color="gray.300" display="flex" flexDir="row" gap="4">
            {comments && (
                <Button
                    size="xs"
                    iconSpacing="1"
                    leftIcon={
                        <MdOutlineModeComment
                            color={colors.gray[300]}
                            size="20"
                        />
                    }
                    bgColor="transparent"
                    _hover={{
                        bgColor: 'transparent',
                    }}
                    p="0"
                    onClick={handleCommentClick}
                >
                    <Text as="span" fontWeight="normal">
                        {comments.length === 1
                            ? `${comments.length} ${t('interactive.comment')}`
                            : comments.length > 1
                            ? `${comments.length} ${t('interactive.comments')}`
                            : t('interactive.doComment')}
                    </Text>
                </Button>
            )}

            <Button
                size="xs"
                iconSpacing="1"
                leftIcon={
                    interactions?.favorited ? (
                        <MdStar color={colors.brand.primary} size="20" />
                    ) : (
                        <MdStarOutline color={colors.gray[300]} size="20" />
                    )
                }
                bgColor="transparent"
                _hover={{
                    bgColor: 'transparent',
                }}
                p="0"
                onClick={handleFavoriteClick}
            >
                <Text as="span" fontWeight="normal">
                    {interactions?.favorited
                        ? t('interactive.fav')
                        : t('interactive.doFav')}
                </Text>
            </Button>

            <Button
                size="xs"
                iconSpacing="1"
                leftIcon={
                    interactions?.liked ? (
                        <MdFavorite color={colors.brand.primary} size="18" />
                    ) : (
                        <MdFavoriteBorder color={colors.gray[300]} size="18" />
                    )
                }
                bgColor="transparent"
                _hover={{
                    bgColor: 'transparent',
                }}
                p="0"
                onClick={handleLikeClick}
            >
                {
                    <Text as="span" fontWeight="normal">
                        {interactions?.likes_count === undefined
                            ? t('interactive.doLike')
                            : interactions?.likes_count === 1
                            ? `${interactions?.likes_count} ${t(
                                  'interactive.like'
                              )}`
                            : interactions?.likes_count > 1
                            ? `${interactions?.likes_count} ${t(
                                  'interactive.likes'
                              )}`
                            : t('interactive.doLike')}
                    </Text>
                }
            </Button>
        </Box>
    );
}

export default InteractiveButton;
