import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import runOneSignal from '../../services/OneSignal/oneSignal';
import { handlePromptView } from '../../services/utils';
import FsModal from '../Fs/Modal';

import Header from '../Header';
import Menu from '../Menu';
import Translator from '../Translator';

type EventInstall = Event & {
    prompt(): void;
    userChoice: any;
};

function FixedPage() {
    const [deferedPrompt, setDeferedPrompt] = useState<
        EventInstall | undefined
    >();
    const { companyConfig } = useConfigContext();
    const [oneSignalInitiated, setOneSignalInitiated] = useState(false);
    const [showAppUpdateAlert, setShowAppUpdateAlert] = useState(false);

    function promptInstall(ignoreTimeout = false) {
        if (deferedPrompt) {
            const test = handlePromptView(600, ignoreTimeout);
            if (test) {
                deferedPrompt.prompt();
            }
        }
    }

    function handleUpdateApplication() {
        window.installingWorker &&
            window.installingWorker.postMessage({ type: 'SKIP_WAITING' });
        setShowAppUpdateAlert(false);
    }

    useEffect(() => {
        !oneSignalInitiated &&
            companyConfig?.one_signal_app_id &&
            runOneSignal({
                appID: companyConfig.one_signal_app_id,
                localhost: !(process.env.NODE_ENV === 'production'),
                setInitialized: setOneSignalInitiated,
            });
    }, [companyConfig, oneSignalInitiated]);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e: any) => {
            e.preventDefault();
            setDeferedPrompt(e);
        });
    }, []);

    useEffect(() => {
        window.addEventListener('showUpdatePrompt', (e: any) => {
            setShowAppUpdateAlert(true);
        });
    }, []);

    return (
        <VStack
            h="100vh"
            spacing="0"
            align="stretch"
            onClick={() => {
                promptInstall();
            }}
        >
            <FsModal
                isCentered
                isOpen={showAppUpdateAlert}
                onClose={() => setShowAppUpdateAlert(false)}
                modalContentProps={{
                    bgColor: 'yellow.50',
                    mx: 4,
                }}
            >
                <Alert
                    status="warning"
                    variant="subtle"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    bgColor="transparent"
                >
                    <AlertIcon boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1}>
                        <Translator path="translations.update.haveAUpdate" />
                    </AlertTitle>
                    <AlertDescription>
                        <Box
                            as="button"
                            onClick={() => handleUpdateApplication()}
                            textDecor="underline"
                            fontSize="xs"
                        >
                            <Translator path="translations.update.clickHere" />
                        </Box>
                    </AlertDescription>
                </Alert>
            </FsModal>
            <Header
                installApp={promptInstall}
                abbleToInstall={!!deferedPrompt}
            />
            <Box pb="20">
                <Outlet />
            </Box>
            <Menu />
        </VStack>
    );
}

export default FixedPage;
