import { Box, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import { fetchRegister } from '../../services/api';
import { Terms } from '../../types/contentTypes';
import FsSkeletonText from '../Fs/Skeletons/SkeletonText';
import Translator from '../Translator';

function NotHaveRegistrationComponent() {
    const { companyConfig } = useConfigContext();

    const { isLoading, data } = useQuery<Terms>({
        queryKey: ['register'],
        queryFn: () =>
            fetchRegister(companyConfig ? companyConfig.client_token : ''),
    });

    return (
        <>
            <Helmet>
                <title>{`${t('notHaveAccount')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <Text
                as="h1"
                fontSize="xl"
                color="gray.600"
                fontWeight="700"
                textAlign="center"
                mb="8"
            >
                <Translator path="translations.notHaveAccount" />
            </Text>
            {isLoading ? (
                <FsSkeletonText />
            ) : (
                <Box
                    dangerouslySetInnerHTML={{ __html: data ? data.text : '' }}
                />
            )}
        </>
    );
}

export default NotHaveRegistrationComponent;
