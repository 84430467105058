import { Language } from './../../../types/translation';
const es: Language = {
    translations: {
        form: {
            required: 'Este campo es obligatorio',
            incorrect:
                'Información incorrecta, asegúrese de escribir los campos correctamente',
            error: 'Tuvimos un problema, inténtalo de nuevo más tarde.',
            removeAccount: 'Eliminamos tu cuenta',
            alterAccountSuccess: 'Cambiamos tus datos',
            email: 'Introduce un correo electrónico',
            sendMessage: 'Tu mensaje fue enviado',
            equals: 'Las contraseñas no son similares',
            passRequirementes:
                'La contraseña debe tener al menos una letra minúscula, una letra mayúscula y un número',
            sixCaracteres: 'Mínimo de 6 caracteres',
            alterPasswordSuccess: 'Tu contraseña ha sido cambiada exitosamente',
            alterPasswordInvalid: 'Contraseña actual inválida',
            send: 'Mandar',
            addComment: 'Añadir un comentario...',
            successComment: 'Tu comentario fue enviado',
            acceptTerms: 'Necesitas aceptar los términos de uso',
        },
        login: {
            password: 'Contraseña',
            login: 'Entrar',
            welcomeText: 'Entra en tu',
            account: 'Cuenta',
            forgotPassword: '¿No recuerdas tu contraseña?',
            createNow: 'Crea ahora',
            dontHave: '¿No tienes una cuenta?',
            termsOfUse: 'Terminos de uso',
        },
        search: {
            noResultsFound: 'No se han encontrado resultados para "{{query}}".',
        },
        back: 'VOLVER',
        close: 'CERCA',
        termsOfUse: 'Terminos de uso',
        notHaveAccount: '¿No tienes una cuenta?',
        installApp: 'Instalar aplicación',
        forgotPassword: {
            title: 'Olvido la contraseña?',
            description:
                '¡No te preocupes, te hemos enviado instrucciones para recuperarlo!',
            button: 'Recuperar Contraseña',
            dontHaveAcccount: '¿No tienes una cuenta?',
            createNow: 'Crea ahora',
        },
        feed: {
            Download: 'Descargas',
            feed: 'Feed',
            Link: 'Enlaces',
            Podcast: 'Podcasts',
            Vídeo: 'Vídeos',
            Notícia: 'Noticias',
            Unidade: 'Unidad',
            TVOnline: 'Televisión en línea',
            Favorito: 'Favoritos',
            Busca: {
                title: 'Buscar',
                noResult: 'No hay resultados',
                result: 'Resultado',
                results: 'Resultados',
            },
        },
        types: {
            Download: 'Descargar',
            Link: 'Enlace',
            Podcast: 'Podcast',
            Vídeo: 'Video',
            Notícia: 'Noticia',
        },
        interactive: {
            doFav: 'Favorito',
            favs: 'Favoritos',
            fav: 'Favorito',
            doLike: 'Like',
            like: 'Like',
            likes: 'Likes',
            comment: 'Comentario',
            comments: 'Comentarios',
            doComment: 'Comentario',
        },
        menu: {
            more: 'más',
            profile: 'Mi Perfil',
            sendMessage: 'Hable con nosotros',
            terms: 'Terminos de uso',
            logout: 'Cerrar sesión',
            moreContent: 'MÁS CONTENIDOS',
            pessoalInfo: 'INFORMACIONES PERSONALES',
        },
        profile: {
            title: 'Mi Perfil',
            name: 'Nombre Completo',
            email: 'Correo electrónico',
            change: 'Cambiar mi contraseña',
            save: 'Ahorrar',
            deleteAccount: 'Borrar mi cuenta',
            description: 'Edita tu información',
            atention: 'Atención',
            modalDescription: [
                'Si continúa, su cuenta y todos los datos, incluido el historial y la información, se eliminarán de forma permanente.',
                'Escribe "<strong>CONFIRMAR</strong>" para eliminar tu cuenta.',
            ],
            pass: 'CONFIRMAR',
            toExclude: 'para borrar tu cuenta',
            typeIt: 'Escribelo',
            delete: 'Borrar',
            cancel: 'Cancelar',
        },
        sendMessage: {
            title: 'Hable con nosotros',
            description:
                'El siguiente mensaje estará dirigido a una persona responsable de su empresa, recuerde agregar un formulario de contacto en el mensaje si desea obtener una respuesta de ella.',
            message: 'Mensaje',
            send: 'Enviar mensaje',
            subject: 'Sujeto',
            help: 'Añade un mensaje para pasar al responsable de tu empresa.',
            addMessage: 'Agregar mensaje',
        },
        changePassword: {
            title: 'Cambiar la contraseña',
            description: 'Cambia tu contraseña',
            actual: 'Contraseña Actual',
            alter: 'Actualiza contraseña',
            confirm: 'Confirmar la nueva contraseña',
            new: 'Nueva contraseña',
        },
        firstTime: {
            accept: 'He leído y estoy de acuerdo con estos términos de uso',
            button: 'Acepto los términos de uso',
            confirmationPassword: 'Confirmar la nueva contraseña',
            confirmation: 'Confirmación',
            new: 'Nueva contraseña',
            title: 'Primera vez',
        },
        update: {
            haveAUpdate: '¡Hay una actualización disponible!',
            clickHere: 'Haga clic aquí para actualizar',
        },
        errors: {
            domainTitle: 'Dominio no encontrado',
            domainDescription: 'cliente especificado no encontrado',
            defaultErrorTitle: 'Ops....',
            defaultErrorDescription:
                'Lo sentimos, se ha producido un error inesperado.',
        },
        tvonline: {
            description:
                'En el vídeo de arriba puedes ver el Contenido de Corporate TV.',
        },
    },
};

export default es;
