import { Box, Center, Spinner, VStack } from '@chakra-ui/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useConfigContext } from '../../context/configContext';
import { fetchUnitFeedPage, fetchUnitHighlights } from '../../services/api';
import { Content } from '../../types/contentTypes';
import { DetachListComponent } from '../../components/DetachListComponent';
import ContentListComponent from '../../components/ContentListComponent';
import FeedHeader from '../../components/FeedHeader';
import FeedSkeleton from '../../components/Fs/Skeletons/FeedSkeleton';
import { t } from 'i18next';

function UnitFeed() {
    const {
        data: dataFeed,
        isLoading: isLoadingFeed,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<Content[]>({
        queryKey: ['unit-feed'],
        queryFn: ({ pageParam = 1 }) =>
            fetchUnitFeedPage(pageParam).then((content) => content),
        getNextPageParam: (lastPage, pages) =>
            lastPage.length === 25 ? pages.length + 1 : undefined,
    });

    const { data: detachData, isLoading: isLoadingDetach } = useQuery({
        queryKey: ['unit-detach'],
        queryFn: () => fetchUnitHighlights().then((content) => content),
    });

    const { companyConfig } = useConfigContext();

    const content = dataFeed?.pages
        ?.flatMap((page) => page)
        .filter((content) => content.type !== 'Download');

    const feedContent = content?.filter((content) => !content.is_highlight);
    const detachContent = detachData?.filter(
        (content) => content.type !== 'Download'
    );

    const ifLastContentWasViewd = () => {
        hasNextPage && fetchNextPage();
    };

    return (
        <>
            <Helmet>
                <title>{`${t('feed.Unidade')} - ${companyConfig?.name}`}</title>
            </Helmet>

            <FeedHeader title={t('feed.Unidade')} search />

            {isLoadingFeed === true || isLoadingDetach === true ? (
                <FeedSkeleton />
            ) : (
                <VStack align="stretch" spacing="0">
                    {detachContent && detachContent.length > 0 && (
                        <Box display="flex" flexDir="column" w="full">
                            <Box>
                                <DetachListComponent
                                    detachContent={detachContent}
                                />
                            </Box>
                        </Box>
                    )}
                    {feedContent && (
                        <Box px="4">
                            <ContentListComponent
                                feedContent={feedContent}
                                onLastContentView={ifLastContentWasViewd}
                            />
                        </Box>
                    )}
                </VStack>
            )}
            {isFetchingNextPage && (
                <Box position="fixed" bottom="90px" w="full">
                    <Center>
                        <Spinner />
                    </Center>
                </Box>
            )}
        </>
    );
}

export default UnitFeed;
