import { Text, useTheme, VStack } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import useHighlightPerUrl from '../../hooks/highlightPerUrl';

type MenuItemProps = {
    url: string;
    title: string;
    Icon: IconType;
    onClick?: () => void;
};

function MenuItem({ title, url, Icon, onClick }: MenuItemProps) {
    const { colors } = useTheme();
    const isHighlightPerUrl = useHighlightPerUrl(url);

    return (
        <Link onClick={() => onClick && onClick()} to={url}>
            <VStack>
                <Icon
                    size="20"
                    color={
                        isHighlightPerUrl
                            ? colors.brand.primary
                            : colors.gray[200]
                    }
                />
                <Text
                    fontSize="xs"
                    color={
                        isHighlightPerUrl
                            ? colors.brand.primary
                            : colors.gray[300]
                    }
                    mt="2"
                >
                    {title}
                </Text>
            </VStack>
        </Link>
    );
}

export default MenuItem;
