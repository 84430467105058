import { Text, Textarea, TextareaProps, useTheme } from '@chakra-ui/react';
import { t } from 'i18next';
import { FieldError } from 'react-hook-form';
import { lighten } from 'polished';

type FsTextAreaProps = TextareaProps & {
    label?: string;
    error?: FieldError;
};

function FsTextAreaComponent({ label, error, ...rest }: FsTextAreaProps) {
    const { colors } = useTheme();

    return (
        <>
            {label && (
                <Text fontSize="sm" mb="1">
                    {label}
                </Text>
            )}
            <Textarea
                p="10px"
                w="100%"
                height="30"
                bg="white"
                borderRadius="6px"
                _placeholder={{
                    color: 'gray.200',
                }}
                color="gray.600"
                fontSize="mg"
                sx={{
                    '::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: lighten(0.2, colors.brand.primary),
                        height: '25%',
                    },
                }}
                {...rest}
            />
            {error?.message && (
                <Text color="red.300" fontSize="xs" mt="1">
                    {t(error.message)}
                </Text>
            )}
        </>
    );
}

export default FsTextAreaComponent;
