import { Box, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { Comment, Content } from '../../types/contentTypes';
import InteractiveButton from '../InteractiveButtons';
import Player from '../VideoPlayer';
import TagComponent from '../TagComponent';
import { getIntlRelativeDate } from '../../services/intl/intlFormats';
import { useConfigContext } from '../../context/configContext';
import Gallery from '../Gallery';
import { useEffect, useRef, useState } from 'react';
import { postComment } from '../../services/api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useFsToast from '../Fs/Toast';
import FsTextAreaComponent from '../Fs/TextArea';
import { t } from 'i18next';
import './content.css';
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll,
} from 'body-scroll-lock';
import { MdSend } from 'react-icons/md';

type FormData = {
    comment: string;
};

const schema = yup.object({
    comment: yup.string().required('form.required'),
});

type ContentComponentProps = {
    content: Content;
    commentsPromise?: Promise<Comment[]>;
};

export function ContentComponent({
    content,
    commentsPromise,
}: ContentComponentProps) {
    const images = content.assets.image
        ? [content.assets.image, ...content.assets.galery]
        : null;
    const { companyConfig } = useConfigContext();
    const [showComments, setShowComments] = useState(false);
    const [loadedComments, setLoadedComments] = useState<Comment[]>();
    const [isLoading, setIsLoading] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const toast = useFsToast();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            comment: '',
        },
    });

    function handleShowComments() {
        setShowComments(!showComments);
    }

    function handleSendComment(data: FormData) {
        setIsLoading(true);

        postComment(content.id, data.comment)
            .then((response) => {
                setIsLoading(false);
                setLoadedComments(response);
                reset();
                toast({
                    description: t('form.successComment'),
                    status: 'success',
                });
            })
            .catch(() => {
                setIsLoading(false);
                toast({
                    description: t('form.error'),
                    status: 'error',
                });
            });
    }

    useEffect(() => {
        const loadComments = async () => {
            setLoadedComments(await commentsPromise);
        };
        loadComments();
    }, [commentsPromise]);

    useEffect(() => {
        if (containerRef.current) {
            if (showComments) {
                disableBodyScroll(containerRef.current);
            } else {
                enableBodyScroll(containerRef.current);
            }
        }
    }, [showComments]);

    useEffect(() => {
        return () => clearAllBodyScrollLocks();
    }, []);

    return (
        <>
            <Box mb="4">
                {!content.assets.video && images && <Gallery items={images} />}
                {content.assets.video && (
                    <Box bgColor="blackAlpha.300" p="2" mx="4">
                        <Player video={content.assets.video} />
                    </Box>
                )}
            </Box>
            <Box px="4">
                <HStack fontSize="sm" color="brand.primary">
                    {content.tags[0] && (
                        <TagComponent
                            title={content.tags[0].toUpperCase()}
                            key={content.tags[0].toUpperCase()}
                        />
                    )}
                    {content.tags.length >= 1 && <Text as="span"> • </Text>}
                    <Text as="span">
                        {getIntlRelativeDate(
                            companyConfig?.language,
                            new Date(content.published_at)
                        )}
                    </Text>
                </HStack>

                <VStack spacing="0" align="stretch">
                    <Box pt="4" mb="20">
                        <Text fontSize="xl" fontWeight="bold">
                            {content.title}
                        </Text>
                        {content.description !== '' && (
                            <Box
                                className="content"
                                color="gray.300"
                                fontSize="md"
                                dangerouslySetInnerHTML={{
                                    __html: content.description,
                                }}
                            />
                        )}
                    </Box>
                </VStack>
            </Box>
            <>
                <Box
                    className="noLibras"
                    display={`${showComments ? '' : 'none'}`}
                    bgColor="blackAlpha.700"
                    position="fixed"
                    zIndex="sticky"
                    left="0px"
                    top="0px"
                    w="100vw"
                    h="100vh"
                    onClick={() => {
                        setShowComments(false);
                    }}
                />
                <Box
                    bgColor="gray.150"
                    position="fixed"
                    zIndex="sticky"
                    w="100vw"
                    h={`${showComments ? '70%' : '10'}`}
                    transitionTimingFunction={`${
                        showComments ? 'ease-out' : 'ease-in-out'
                    }`}
                    transitionDuration=".5s, .1s"
                    transitionProperty="height, bottom"
                    bottom="20"
                    borderTop="1px solid"
                    borderColor="gray.200"
                    boxShadow="0 -5px 5px rgba(0, 0, 0, .1)"
                >
                    <Box h="100%" overflowY="hidden">
                        <Box
                            w="100%"
                            h="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Box
                                flex="0"
                                px="4"
                                py="2"
                                borderBottom="1px solid"
                                borderColor="gray.200"
                                boxShadow="0 2px 2px rgba(0, 0, 0, .2)"
                            >
                                <InteractiveButton
                                    px="4"
                                    contentId={content.id}
                                    comments={
                                        companyConfig?.is_active_comments
                                            ? loadedComments
                                            : undefined
                                    }
                                    onClickComments={handleShowComments}
                                />
                            </Box>
                            <Box
                                flex="1"
                                overflowY="auto"
                                overflowX="hidden"
                                ref={containerRef}
                            >
                                {loadedComments &&
                                    loadedComments.map((comment, idx) => (
                                        <Box key={idx}>
                                            <Box
                                                fontSize="sm"
                                                color="gray.300"
                                                bgColor="whiteAlpha.500"
                                                px="4"
                                                py="6"
                                                borderBottom={
                                                    idx <
                                                    loadedComments.length - 1
                                                        ? '1px'
                                                        : ''
                                                }
                                                borderBottomColor="blackAlpha.300"
                                            >
                                                <Box mb="2">
                                                    <HStack justifyContent="space-between">
                                                        <Text
                                                            as="span"
                                                            fontWeight="bold"
                                                        >
                                                            {comment.user}
                                                        </Text>
                                                        <Text
                                                            as="span"
                                                            fontSize="xs"
                                                        >
                                                            {getIntlRelativeDate(
                                                                companyConfig?.language,
                                                                new Date(
                                                                    comment.commented_at
                                                                ),
                                                                true
                                                            )}
                                                        </Text>
                                                    </HStack>
                                                </Box>
                                                <Box
                                                    dangerouslySetInnerHTML={{
                                                        __html: comment.comment
                                                            ? comment.comment
                                                            : '',
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    ))}
                            </Box>
                            <Box
                                flex="0"
                                px="4"
                                py="4"
                                boxShadow="0 -2px 2px rgba(0, 0, 0, .2)"
                            >
                                <HStack spacing="0">
                                    <Box flex="1">
                                        <Controller
                                            control={control}
                                            name="comment"
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <FsTextAreaComponent
                                                    placeholder={
                                                        t('form.addComment') ||
                                                        'Add Comment'
                                                    }
                                                    value={value}
                                                    onChange={onChange}
                                                    error={errors.comment}
                                                    fontSize="sm"
                                                    borderRightRadius="none"
                                                    borderRight="none"
                                                    resize="none"
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box flex="0">
                                        <IconButton
                                            aria-label={t('form.send')}
                                            icon={<MdSend />}
                                            color="white"
                                            bgColor="brand.primary"
                                            borderLeftRadius="none"
                                            minH="20"
                                            isActive={isLoading}
                                            isLoading={isLoading}
                                            onClick={handleSubmit((data) =>
                                                handleSendComment(data)
                                            )}
                                        />
                                    </Box>
                                </HStack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        </>
    );
}

export default ContentComponent;
