import { Checkbox, CheckboxProps, Text } from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

type FsCheckboxProps = CheckboxProps & {
    label?: string;
    error?: FieldError;
};

function FsCheckbox({ label, error, ...rest }: FsCheckboxProps) {
    return (
        <>
            <Checkbox {...rest}>
                <Text fontSize="sm">{label}</Text>
            </Checkbox>
            {error && (
                <Text color="red.300" fontSize="xs" mt="1">
                    {error.message}
                </Text>
            )}
        </>
    );
}

export default FsCheckbox;
