import { Skeleton, SkeletonProps } from '@chakra-ui/react';

type FsSkeletonProps = SkeletonProps;

function FsSkeleton({ ...rest }: FsSkeletonProps) {
    return (
        <Skeleton
            startColor="gray.100"
            endColor="gray.200"
            height="100%"
            width="100%"
            borderRadius="lg"
            {...rest}
        />
    );
}

export default FsSkeleton;
