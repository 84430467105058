import { useLocation } from 'react-router-dom';

function useHighlightPerUrl(url: string) {
    const path = useLocation().pathname.split('/');

    if (path.length >= 4) {
        return false;
    }

    return path[2] === url;
}

export default useHighlightPerUrl;
