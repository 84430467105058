import {
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerProps,
    Icon,
    Image,
    Text,
    useTheme,
} from '@chakra-ui/react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { lighten } from 'polished';
import Translator from '../../Translator';

type FsDrawerProps = DrawerProps & {
    children: React.ReactNode;
    logo?: string | null;
    drawerFooter?: React.ReactNode;
};

function FsDrawer({
    children,
    logo,
    drawerFooter = null,
    onClose,
    ...rest
}: FsDrawerProps) {
    const { colors } = useTheme();

    return (
        <Drawer onClose={onClose} {...rest}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader p="0" pl="4">
                    <Button
                        bgColor="transparent"
                        _hover={{
                            bgColor: 'transparent',
                        }}
                        onClick={onClose}
                    >
                        <Text fontSize="2xs">
                            <Translator path="translations.back" />
                        </Text>{' '}
                        <Icon boxSize="1rem" as={MdKeyboardArrowRight} />
                    </Button>
                </DrawerHeader>
                <DrawerBody
                    p="0"
                    px="4"
                    m="4"
                    mt="0"
                    overflowY="auto"
                    overflowX="hidden"
                    sx={{
                        '::-webkit-scrollbar': {
                            width: '2',
                        },
                        '::-webkit-scrollbar-thumb': {
                            background: lighten(0.2, colors.brand.primary),
                            height: '50%',
                            borderRadius: '20',
                        },
                        '::-webkit-scrollbar-track': {
                            background: colors.gray[50],
                            borderRadius: '20',
                            marginTop: '20',
                            marginBottom: '20',
                        },
                    }}
                >
                    {logo && (
                        <Center my="8">
                            <Image src={logo} height="100%" maxHeight="60px" />
                        </Center>
                    )}
                    {children}
                </DrawerBody>
                {drawerFooter && <DrawerFooter>{drawerFooter}</DrawerFooter>}
            </DrawerContent>
        </Drawer>
    );
}

export default FsDrawer;
