import { SkeletonText, SkeletonTextProps } from '@chakra-ui/react';

type FsSkeletonTextProps = SkeletonTextProps;

function FsSkeletonText({ ...rest }: FsSkeletonTextProps) {
    return (
        <SkeletonText
            startColor="gray.100"
            endColor="gray.200"
            noOfLines={10}
            spacing="4"
            skeletonHeight="2"
            width="100%"
            borderRadius="none"
            {...rest}
        />
    );
}

export default FsSkeletonText;
