import { Box, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { changePassword } from '../../services/api';
import { useState } from 'react';
import FixedConfigPage from '../../components/FixedConfigPage';
import { useNavigate } from 'react-router-dom';
import useFsToast from '../../components/Fs/Toast';
import FsInputPassword from '../../components/Fs/InputPassword';
import FsButton from '../../components/Fs/Button';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { useConfigContext } from '../../context/configContext';
import Translator from '../../components/Translator';

type FormData = {
    passwordCurrent: string;
    password: string;
    passwordConfirmation: string;
};

const schema = yup.object({
    passwordCurrent: yup.string().required('form.required'),
    password: yup
        .string()
        .required('form.required')
        .min(6, 'form.sixCaracteres')
        .matches(/[a-z]+/, 'form.passRequirementes')
        .matches(/[A-Z]+/, 'form.passRequirementes')
        .matches(/\d+/, 'form.passRequirementes'),
    passwordConfirmation: yup
        .string()
        .required('form.required')
        .oneOf([yup.ref('password')], 'form.equals'),
});

export function ChangePassword() {
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            passwordCurrent: '',
            password: '',
            passwordConfirmation: '',
        },
    });
    const { companyConfig } = useConfigContext();
    const navigate = useNavigate();
    const toast = useFsToast();

    function handleConfirm(data: FormData) {
        setIsLoading(true);
        changePassword(
            data.passwordCurrent,
            data.password,
            data.passwordConfirmation
        )
            .then(() => {
                toast({
                    description: t('form.alterPasswordSuccess'),
                    status: 'success',
                });
                navigate(-1);
            })
            .catch((error) => {
                if (error.response.status === 422) {
                    toast({
                        description: t('form.alterPasswordInvalid'),
                        status: 'warning',
                    });
                    reset();
                } else {
                    toast({
                        description: t('form.error'),
                        status: 'error',
                    });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <>
            <Helmet>
                <title>{`${t('changePassword.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>
            <FixedConfigPage>
                <Text
                    as="h1"
                    fontSize="3xl"
                    color="gray.600"
                    fontWeight="700"
                    textAlign="center"
                    mb="8"
                >
                    <Translator path="translations.changePassword.title" />
                </Text>
                <Text>
                    <Translator path="translations.changePassword.description" />
                </Text>

                <VStack align="stretch" spacing="5" mt="6">
                    <Box>
                        <Controller
                            control={control}
                            name="passwordCurrent"
                            render={({ field: { onChange, value } }) => (
                                <FsInputPassword
                                    label={
                                        t('changePassword.actual') ||
                                        'Actual Password'
                                    }
                                    placeholder={
                                        t('changePassword.actual') ||
                                        'Actual Password'
                                    }
                                    onChange={onChange}
                                    value={value}
                                    error={errors.passwordCurrent}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="password"
                            render={({ field: { onChange, value } }) => (
                                <FsInputPassword
                                    label={
                                        t('changePassword.new') ||
                                        'New Password'
                                    }
                                    placeholder={
                                        t('changePassword.new') ||
                                        'New Password'
                                    }
                                    onChange={onChange}
                                    value={value}
                                    error={errors.password}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="passwordConfirmation"
                            render={({ field: { onChange, value } }) => (
                                <FsInputPassword
                                    label={
                                        t('changePassword.confirm') ||
                                        'Password Confirmation'
                                    }
                                    placeholder={
                                        t('changePassword.confirm') ||
                                        'Password Confirmation'
                                    }
                                    onChange={onChange}
                                    value={value}
                                    error={errors.passwordConfirmation}
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <FsButton
                            onClick={handleSubmit(handleConfirm)}
                            block={true}
                            isLoading={isLoading}
                            isDisabled={isLoading}
                        >
                            <Translator path="translations.changePassword.alter" />
                        </FsButton>
                    </Box>
                </VStack>
            </FixedConfigPage>
        </>
    );
}

export default ChangePassword;
