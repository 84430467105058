import {
    Box,
    Center,
    Divider,
    HStack,
    Icon,
    Image,
    Text,
    useTheme,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
    MdFilePresent,
    MdLink,
    MdOutlineFileDownload,
    MdStar,
    MdStarOutline,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { favorite, unfavorite } from '../../services/api';
import { Content } from '../../types/contentTypes';
import { useInView } from 'react-intersection-observer';

type SimpleContentComponentProps = {
    content: Content;
    onContentView?: () => void;
};

export function SimpleContentComponent({
    content,
    onContentView,
}: SimpleContentComponentProps) {
    const { ref, inView } = useInView();

    const [isFav, setIsFav] = useState(
        content.is_favorited ? content.is_favorited : false
    );
    const { colors } = useTheme();
    const link = content.assets.link ? content.assets.link : undefined;
    const file = content.assets.file ? content.assets.file : undefined;

    function handleFavoriteClick() {
        isFav ? unfavorite(content.id) : favorite(content.id);
        setIsFav(!isFav);
    }

    useEffect(() => {
        inView && onContentView && onContentView();
    }, [inView, onContentView]);

    return (
        <Box ref={ref}>
            <HStack justifyContent="space-between">
                <HStack>
                    {content.type === 'Download' && (
                        <Image
                            boxSize="64px"
                            borderRadius="12"
                            src={
                                content.assets.image
                                    ? content.assets.image
                                    : undefined
                            }
                            fit="cover"
                            fallback={
                                <Center
                                    boxSize="64px"
                                    bg="white"
                                    borderRadius="12"
                                    flexShrink="0"
                                >
                                    <Icon
                                        as={MdFilePresent}
                                        boxSize="25px"
                                        color="brand.primary"
                                    />
                                </Center>
                            }
                        />
                    )}
                    <Box>
                        <Text
                            fontWeight="bold"
                            fontSize="md"
                            wordBreak="break-word"
                        >
                            {content.title}
                        </Text>
                        {content.type === 'Link' && (
                            <Text
                                fontSize="xs"
                                noOfLines={1}
                                wordBreak="break-word"
                            >
                                {content.assets.link}
                            </Text>
                        )}
                    </Box>
                </HStack>
                <HStack>
                    <Link to={`${link || file}`} target="_blank">
                        {content.type === 'Link' && (
                            <MdLink color={colors.gray[300]} size="20" />
                        )}
                        {content.type === 'Download' && (
                            <MdOutlineFileDownload
                                color={colors.gray[300]}
                                size="20"
                            />
                        )}
                    </Link>
                    <Box as="button" onClick={handleFavoriteClick}>
                        {isFav ? (
                            <MdStar color={colors.brand.primary} size="20" />
                        ) : (
                            <MdStarOutline color={colors.gray[300]} size="20" />
                        )}
                    </Box>
                </HStack>
            </HStack>
            <Divider my="6" />
        </Box>
    );
}

export default SimpleContentComponent;
