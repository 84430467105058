import {
    Comment,
    Content,
    Interactions,
    Likes,
    PostMessageError,
    Terms,
} from '../types/contentTypes';
import { User } from '../context/userAuthContext';
import axios, { GenericAbortSignal } from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
});

export { api };

export async function fetchFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/feed?page=${page}`)
        .then((response) => response.data);
}

export async function fetchFeedHighlights() {
    return api
        .get<Content[]>(`content/feed/highlights`)
        .then((response) => response.data);
}

export async function fetchUnitFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/branch?page=${page}`)
        .then((response) => response.data);
}

export async function fetchUnitHighlights() {
    return api
        .get<Content[]>(`content/branch/highlights`)
        .then((response) => response.data);
}

export async function fetchDownloadsFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/download?page=${page}`)
        .then((response) => response.data);
}

export async function fetchNewsFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/news?page=${page}`)
        .then((response) => response.data);
}

export async function fetchNewsHighlights() {
    return api
        .get<Content[]>(`content/news/highlights`)
        .then((response) => response.data);
}

export async function fetchFavoritesFeedPage(page = 1) {
    return api
        .get<Content[]>(`/content/favorites?page=${page}`)
        .then((response) => response.data);
}

export async function fetchFavoritesHighlights() {
    return api
        .get<Content[]>(`content/favorites/highlights`)
        .then((response) => response.data);
}

export async function fetchLinkFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/link?page=${page}`)
        .then((response) => response.data);
}

export async function fetchPodcastFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/podcast?page=${page}`)
        .then((response) => response.data);
}

export async function fetchPodcastHighlights() {
    return api
        .get<Content[]>(`content/podcast/highlights`)
        .then((response) => response.data);
}

export async function fetchVideoFeedPage(page = 1) {
    return api
        .get<Content[]>(`content/video?page=${page}`)
        .then((response) => response.data);
}

export async function fetchVideoHighlights() {
    return api
        .get<Content[]>(`content/video/highlights`)
        .then((response) => response.data);
}

export async function like(id: number, signal?: GenericAbortSignal) {
    return api
        .get<Likes>(`content/${id}/like`, { signal })
        .then((response) => response.data);
}

export async function unlike(id: number, signal?: GenericAbortSignal) {
    return api
        .get<Likes>(`content/${id}/unlike`, { signal })
        .then((response) => response.data);
}

export async function favorite(id: number, signal?: GenericAbortSignal) {
    return api
        .get(`content/${id}/favorite`, { signal })
        .then((response) => response.status);
}

export async function unfavorite(id: number, signal?: GenericAbortSignal) {
    return api
        .get(`/content/${id}/unfavorite`, { signal })
        .then((response) => response.status);
}

export async function fetchContent(id: number) {
    return api.get<Content>(`/content/${id}`).then((response) => response.data);
}

export async function fetchComments(id: number) {
    return api
        .get<Comment[]>(`/content/${id}/comment`)
        .then((response) => response.data);
}

export function authenticateUser(
    clientToken: string,
    username: string,
    password: string
) {
    return api.put<User>('/user/auth', {
        client_token: clientToken,
        username,
        password,
    });
}

export function fetchTermsOfUse(clientToken: string) {
    return api
        .get<Terms>(`/client/${clientToken}/terms-of-use`)
        .then((response) => response.data);
}

export function fetchRegister(clientToken: string) {
    return api
        .get<Terms>(`/user/${clientToken}/register`)
        .then((response) => response.data);
}

export function postComment(contentId: number, comment: string) {
    return api
        .post<Comment[]>(`/content/${contentId}/comment`, { comment })
        .then((response) => response.data);
}

export function changePassword(
    current: string,
    newPassword: string,
    confirm: string
) {
    return api.put(`/user/change-password`, {
        password_current: current,
        password: newPassword,
        password_confirm: confirm,
    });
}

export function changeProfile(username: string, name: string, email: string) {
    return api.put(`/user/change-profile`, {
        username,
        name,
        email,
    });
}

export function fetchOptIn(
    password: string,
    passwordConfirm: string,
    optIn: boolean
) {
    return api.post(`/user/opt-in`, {
        opt_in: optIn,
        password,
        password_confirm: passwordConfirm,
    });
}

export function contactUs(subject: string, message: string) {
    return api.post<[] | PostMessageError>(`/user/send-message`, {
        subject,
        message,
    });
}

export function sendForgotPasswordEmail(username: string, clientToken: string) {
    return api.post(`/user/forgot-password`, {
        client_token: clientToken,
        username,
    });
}

export function removeMyData(confirmed: boolean) {
    return api.delete(`/user/remove-account`, {
        data: {
            confirmed,
        },
    });
}

export function getSearchResults(query: string) {
    return api
        .get<[] | Content[]>(`/content/search?query=${query}`)
        .then((response) => response.data);
}

export function fetchManifest() {
    let hostname = window.location.host.split('.')[0];

    hostname = hostname.startsWith('localhost') ? 'farolapp' : hostname;

    return api
        .get(`/client/${hostname}/manifest.webmanifest`)
        .then((response) => response.data);
}

export function readContent(contentId: number) {
    return api
        .get(`/content/${contentId}/read`)
        .then((response) => response.data);
}

export function fetchInteractions(contentId: number) {
    return api
        .get<Interactions>(`/content/${contentId}/interactions`)
        .then((response) => response.data);
}
