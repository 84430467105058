import {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from 'react';
import { CompanyConfig, ConfigContextProps } from '../types/contentTypes';
import { initI18N } from '../services/i18n';
import { COMPANYCONFIGKEY } from '../services/localStorageKeys';
import { getSubDomain } from '../services/utils';

type ConfigContextProviderProps = {
    children: ReactNode;
};

const ConfigContext = createContext<ConfigContextProps>(
    {} as ConfigContextProps
);

function getLanguage(language: string) {
    return language === 'pt' ? 'pt-br' : language === 'es' ? 'es' : 'en';
}

export function ConfigContextProvider({
    children,
}: ConfigContextProviderProps) {
    async function fetchCompanyConfig(
        subdomain: string
    ): Promise<CompanyConfig | undefined> {
        if (navigator.onLine) {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/client/${subdomain}`
            );
            if (response.status !== 200) {
                setNotFound(true);
                return undefined;
            }
            return response.json();
        } else {
            const companyConfigInLocalStorage =
                localStorage.getItem(COMPANYCONFIGKEY);
            const response = companyConfigInLocalStorage
                ? (JSON.parse(companyConfigInLocalStorage) as CompanyConfig)
                : undefined;
            return response;
        }
    }

    const [companyConfig, setCompanyConfig] = useState<CompanyConfig>();
    const [isLoadingCompanyConfig, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        async function getCompanyConfig() {
            setIsLoading(true);
            await fetchCompanyConfig(
                getSubDomain(window.location.hostname)
            ).then((response) => {
                if (response) {
                    setCompanyConfig(response);
                    localStorage.setItem(
                        COMPANYCONFIGKEY,
                        JSON.stringify(response)
                    );

                    initI18N(getLanguage(response.language));
                } else {
                    initI18N();
                }
                setIsLoading(false);
            });
        }

        getCompanyConfig();
    }, []);

    return (
        <ConfigContext.Provider
            value={{ companyConfig, isLoadingCompanyConfig, notFound }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export const useConfigContext = () => useContext(ConfigContext);
