import { Box, Image, useTheme } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';
import { Pagination, Navigation } from 'swiper';

type GaleryProps = {
    items: string[];
};

export function Gallery({ items }: GaleryProps) {
    const { colors } = useTheme();

    return (
        <Swiper
            pagination={{
                clickable: true,
                el: '.elContentNavigationBottom',
            }}
            navigation={true}
            slidesPerView={1}
            spaceBetween={30}
            modules={[Pagination, Navigation]}
            className="contentSwiperGallery"
            style={{
                //@ts-ignore
                '--swiper-pagination-color': colors.brand.primary,
                '--swiper-pagination-bullet-inactive-color':
                    colors.brand.primary,
                '--swiper-pagination-bullet-inactive-opacity': 0.3,
                borderRadius: '2px',
            }}
        >
            {items.map((image, idx) => (
                <SwiperSlide key={idx}>
                    <Box mx="4">
                        <Image src={image} borderRadius="lg" />
                    </Box>
                </SwiperSlide>
            ))}

            <Box
                className="elContentNavigationBottom"
                display="flex"
                flexDir="row"
                alignItems="center"
                justifyContent="center"
                mt="4"
                gap="2"
            />
        </Swiper>
    );
}

export default Gallery;
