import { Box, VStack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useConfigContext } from '../../context/configContext';
import { getSearchResults } from '../../services/api';
import { DetachListComponent } from '../../components/DetachListComponent';
import { ContentListComponent } from '../../components/ContentListComponent';
import FeedSkeleton from '../../components/Fs/Skeletons/FeedSkeleton';
import { t } from 'i18next';
import FeedHeader from '../../components/FeedHeader';
function SearchFeed() {
    const [searchParams] = useSearchParams();
    const queryParam = searchParams.get('query');
    const queryText = queryParam ? queryParam : '';

    const { companyConfig } = useConfigContext();

    const { isLoading, data } = useQuery({
        queryKey: [`search-feed-${queryText}`],
        queryFn: () => getSearchResults(queryText).then((content) => content),
    });

    const content = data?.filter((content) => {
        return content.type !== 'Download';
    });

    const detachContent = content?.filter((content) => content.is_highlight);
    const feedContent = content?.filter((content) => !content.is_highlight);

    return (
        <>
            <Helmet>
                <title>{`${t('feed.Busca.title')} - ${
                    companyConfig?.name
                }`}</title>
            </Helmet>

            <FeedHeader title={t('feed.Busca.title')} search />

            {isLoading === true ? (
                <FeedSkeleton />
            ) : (
                <VStack align="stretch" spacing="0">
                    {content?.length === 0 && (
                        <Box px="4">
                            <Text fontSize="sm">
                                {t('search.noResultsFound', {
                                    query: queryText,
                                })}
                            </Text>
                        </Box>
                    )}

                    {detachContent && detachContent.length > 0 && (
                        <Box display="flex" flexDir="column" w="full">
                            <Box>
                                <DetachListComponent
                                    detachContent={detachContent}
                                />
                            </Box>
                        </Box>
                    )}
                    {feedContent && (
                        <Box px="4">
                            <ContentListComponent feedContent={feedContent} />
                        </Box>
                    )}
                </VStack>
            )}
        </>
    );
}

export default SearchFeed;
