import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

type FsInputSearchProps = {
    value: string;
    onSearch: () => void;
    setSearchInputValue: (value: string) => void;
};

function FsInputSearch({
    onSearch,
    setSearchInputValue,
    value,
}: FsInputSearchProps) {
    return (
        <InputGroup>
            <InputRightElement onClick={onSearch}>
                <Search2Icon />
            </InputRightElement>
            <Input
                type="text"
                bgColor="white"
                value={value}
                onChange={(e) => setSearchInputValue(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onSearch();
                    }
                }}
            />
        </InputGroup>
    );
}

export default FsInputSearch;
