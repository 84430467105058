import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
type languages = 'pt' | 'en' | 'es' | undefined;

export function getIntlDate(language: languages = 'pt', publishedDate: Date) {
    const intlLanguageFormat = language === 'pt' ? 'pt-br' : language;

    const intlOptions: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };
    return Intl.DateTimeFormat(intlLanguageFormat, intlOptions).format(
        publishedDate
    );
}

export function getIntlRelativeDate(
    language: languages = 'pt',
    publishedDate: Date,
    withoutSuffix: boolean = false
) {
    const intlLanguageFormat = language === 'pt' ? 'pt-br' : language;

    return moment(publishedDate)
        .locale(intlLanguageFormat)
        .fromNow(withoutSuffix);
}
